import React from "react";
import { Box, Typography, styled } from "@mui/material";

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

const WhyWork = () => {

    return (
        <StyledBox>
            <StyledTypography variant="h4">
                Why Work Here
            </StyledTypography>
            <StyledParagraph>
                We are proud to offer competitive benefits that enable healthy and fulfilling careers at Fire Station
            </StyledParagraph>
        </StyledBox>
    );
};

export default WhyWork;
