import React from "react";
import { Box, Grid, Typography, Card, CardContent, styled } from '@mui/material';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CampaignIcon from '@mui/icons-material/Campaign';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BarChartIcon from '@mui/icons-material/BarChart';

const teams = [
    { name: 'Business Development', icon: <BusinessCenterIcon /> },
    { name: 'Communications', icon: <CampaignIcon /> },
    { name: 'Customer Support', icon: <SupportAgentIcon /> },
    { name: 'Data & Research', icon: <DataUsageIcon /> },
    { name: 'Editorial & Video', icon: <VideoLibraryIcon /> },
    { name: 'Engineering', icon: <EngineeringIcon /> },
    { name: 'Finance & Administration', icon: <AttachMoneyIcon /> },
    { name: 'Legal & Compliance', icon: <GavelIcon /> },
    { name: 'Marketing', icon: <LocalOfferIcon /> },
    { name: 'Ops, Strats & PM', icon: <BarChartIcon /> },
    { name: 'Product & Design', icon: <PeopleIcon /> },
    { name: 'Security & IT Helpdesk', icon: <SecurityIcon /> },
];

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

const TeamSection: React.FC = () => {
    return (

        <Box sx={{ marginTop: '40px' }}>
            <StyledBox>
                <StyledTypography variant="h4">
                    Choose Your Team
                </StyledTypography>
                <StyledParagraph>
                    Select a team most relevant to your interests and experience to view job openings.
                </StyledParagraph>
            </StyledBox>
            <Grid container spacing={3}>
                {teams.map((team, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '20px',
                                border: '1px solid #e0e0e0',
                                borderRadius: '16px',
                                transition: '0.3s',
                                '&:hover': {
                                    borderColor: '#920000',
                                },
                            }}
                        >
                            <Box sx={{ marginRight: '16px' }}>
                                {team.icon}
                            </Box>
                            <CardContent>
                            <Typography variant="body2" component="div" sx={{ fontWeight: 'bold' }}>
                            {team.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TeamSection;
