import React from "react";
import { Box, Typography, Button, TextField, styled } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: "400px",
  width: "100%",
  padding: "2rem",
  border: "1px solid #e0e0e0",
  borderRadius: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
    padding: "1rem",
    margin: "0 1rem",
  },
  "& h6": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "& form": {
    margin: "2rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "& input::placeholder": {
    fontSize: "12px",
  },
}));

const Password = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        bgcolor: "#ffffff",
        padding: "1rem",
      }}
    >
      <Wrapper>
        <Link to="/">
          <Box
            component="img"
            src="/images/firestation-wordmark-red.png" // Path to your logo image
            alt="Fire Station Logo"
            sx={{
              width: {
                xs: "120px", // Mobile size
                sm: "150px", // Tablet size
                md: "200px", // Desktop size
              },
              height: "auto",
              marginBottom: "1rem",
            }}
          />
        </Link>
        <Typography variant="h6" component="h1" gutterBottom>
          Enter Your Password
        </Typography>

        <form>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="Enter your password"
            sx={{ mb: 2 }}
          />

          <Button
            type="button"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: "#920000",
              "&:hover": { backgroundColor: "#c81010" },
              padding: "12px 0",
              textTransform: "none",
            }}
            onClick={handleNext}
          >
            Next
          </Button>

          <Typography
            variant="body2"
            sx={{
              mt: 2, // Margin top
              color: "#920000", // Text color
              cursor: "pointer", // Cursor style
              fontWeight: "bold", // Make text bold
              textAlign: "center", // Center align the text
              width: "100%", // Ensure full-width for proper centering
            }}
            onClick={() => navigate("/")} // Replace with the correct route
          >
            Forgot Password?
          </Typography>
        </form>
      </Wrapper>
    </Box>
  );
};

export default Password;
