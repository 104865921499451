import React from "react";
import { Box, styled } from "@mui/material";

// Define a styled component for the image container
const ImageContainer = styled(Box)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        display: "flex",         // Use flexbox for alignment
        justifyContent: "flex-end", // Align content to the end
        alignItems: "center",       // Center items vertically
        height: "100%",              // Ensure it takes full height if needed
    },
}));

const AboutWelcomeImage = () => {
    return (
        <ImageContainer>
            <img
                src="./images/gold-swap.png"
                style={{ width: "50%", height: "auto" }} // Ensure the image scales correctly
                alt="about welcome"
            />
        </ImageContainer>
    );
};

export default AboutWelcomeImage;
