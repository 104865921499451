import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import {
  Box,
  Grid,
  Container,
  // Typography,
  // useMediaQuery,
  // styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FutureFinance from "../../Components/FutureFinance/FutureFinance";
// import Cryptocurrency from "../../Components/Cryptocurrency/Cryptocurrency";
import Footer from "../../Components/Footer/Footer";
import HeaderTitle from "../../Components/Header/HeaderTitle";
import MobileImage from "../../Components/Header/MobileImage";
import HeaderButtons from "../../Components/Header/HeaderButtons";
import WebImage from "../../Components/Header/WebImage";
import Partners from "../../Components/Partners/Partners";

const Home = () => {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 12, // Number of logos to show at once
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000, // Speed in milliseconds (2 seconds)
  //   responsive: [
  //     {
  //       breakpoint: 1536,
  //       settings: {
  //         slidesToShow: 12,
  //       },
  //     },
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 10,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 8,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 6,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //     {
  //       breakpoint: 400,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          padding: "4rem 0",
          [theme.breakpoints.down("sm")]: { padding: "3rem 0 1rem" },
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} display={{ xs: "block", sm: "none" }}>
              <MobileImage />
            </Grid>
            <Grid item md={7} sm={12}>
              <HeaderTitle />
              <HeaderButtons />
              {/* <HeaderInfoBlock /> */}
            </Grid>
            <Grid item md={5}>
              <WebImage />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Partners />
      <FutureFinance />
      {/* <Cryptocurrency /> */}
      <Footer />
    </>
  );
};

export default Home;