import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Project/Pages/Home/Home";
import PrivacyPolicy from "./Project/Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./Project/Pages/Terms/Terms";
import Markets from "./Project/Pages/Markets/Markets";
import PartnerWithUs from "./Project/Pages/PartnerWithUs/PartnerWithUs";
import About from "./Project/Pages/About/About";
import Careers from "./Project/Pages/Careers/Careers";
import Press from "./Project/Pages/Press/Press";
import Community from "./Project/Pages/Community/Community";
import Blog from "./Project/Pages/Blog/Blog";
import BlogPost from "./Project/Pages/Blog/BlogPost";
import Login from "./Project/Pages/Login/Login";
import Register from "./Project/Pages/Register/Register";
import Business from "./Project/Pages/Register/Business";
import Verification from "./Project/Pages/Register/Verification";
import FireStationVerify from "./Project/Pages/FireStationVerify/FireStationVerify";
import RegisterBusiness from "./Project/Pages/Register/RegisterBusiness";
import Password from "./Project/Pages/Login/Password";
// import CookieConsent from "./Project/Components/Cookies/CookieConsentPopup";
import RiskWarning from "./Project/Pages/RiskWarning/RiskWarning";
import Trade from "./Project/Pages/Trade/Trade";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/partner-with-us" element={<PartnerWithUs />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/press" element={<Press />} />
          <Route path="/community" element={<Community />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/risk-warning" element={<RiskWarning />} />
          <Route path="/official-verification" element={<FireStationVerify />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/business" element={<Business />} />
          <Route path="/register/business-info" element={<RegisterBusiness />} />
          <Route path="/register/verification" element={<Verification />} />
          <Route path="/login/password" element={<Password />} />
        </Routes>
      </BrowserRouter>

      {/* <CookieConsent />  */}
    </div>
  );
}

export default App;
