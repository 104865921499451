import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)({
  padding: "4rem 0 2rem",
  "& p": {
    textAlign: "center",
    marginTop: "2rem",
    paddingTop: "1.4rem",
    color: "rgba(183, 189, 198, 0.8)",
    borderTop: "1px solid rgba(183, 189, 198, 0.3)",
  },
});

export const Bag4 = styled(Box)({
  margin: "2rem 0",
  "& i": {
    color: "#929AA5",
    margin: "0 1rem",
    cursor: "pointer",
  },
  "& i:hover": {
    color: "#920000",
    transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
    cursor: "pointer",
  },
});

export const Bag6 = styled(Box)({
  "& ul li": {
    display: "block !important",
  },
  "& ul li a": {
    textDecoration: "none",
    color: "rgba(112, 122, 138, 0.6)",
    fontSize: "13px",
    padding: "6px",
  },
});

export const FooterIcons = styled(Box)({
  margin: "1rem 0",
  "& i": {
    color: "#929AA5",
    margin: "0 1rem",
    cursor: "pointer",
  },
  "& i:hover": {
    color: "#920000",
    transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
    cursor: "pointer",
  },
  // Show social icons on larger screens
  '@media (min-width: 900px)': {
    display: "flex",
  },
  // Hide social icons on smaller screens
  '@media (max-width: 899px)': {
    display: "none",
  },
});

export const FooterIconsMobile = styled(Box)(({ theme }) => ({
  margin: "1rem 0",
  "& i": {
    color: "#929AA5",
    transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
    cursor: "pointer",
  },
  // Show mobile icons on small screens
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
  // Hide mobile icons on large screens
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
