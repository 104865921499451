import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Box, Grid, Container, Typography, Paper } from "@mui/material";
import { blogPosts } from "./BlogPosts";
import BlogHeader from "./BlogHeader";
import CategorySelector from "./CategorySelector";

const categories = [
  "All",
  "Security",
  "Ecosystem",
  "Community",
  "Markets",
  "Charity",
  "Research",
];

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const filteredPosts =
    selectedCategory === "All"
      ? blogPosts
      : blogPosts.filter((post) => post.category === selectedCategory);

  return (
    <>
      <Navbar />
      <Box
        sx={{
          padding: "4rem 0",
          marginBottom: "4rem",
          "@media (max-width:600px)": { padding: "3rem 0 1rem", marginBottom: "0rem" },
        }}
      >
        <Container>
          <Typography fontWeight={600} variant="h4" gutterBottom>
            Fire Station Blog
          </Typography>

          <BlogHeader />
          <CategorySelector
            categories={categories}
            onSelectCategory={handleSelectCategory}
            selectedCategory={selectedCategory}
          />
          <Grid container spacing={4}>
            {filteredPosts.map((post, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link to={`/blog/${post.slug}`} style={{ textDecoration: "none" }}>
                  <Paper
                    sx={{
                      elevation: 0,
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "20px",
                      padding: 2,
                      transition: "transform 0.3s ease, backgroundColor 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <img
                      src={post.image}
                      alt={post.title}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <Typography variant="h5" sx={{ marginTop: 2, fontWeight: "bold" }}>
                      {post.title}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 0.1, fontWeight: "light" }}>
                      {post.description}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                      {`${new Date(post.date).getFullYear()}-${String(new Date(post.date).getMonth() + 1).padStart(2, "0")}-${String(new Date(post.date).getDate()).padStart(2, "0")}`}
                    </Typography>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Blog;
