import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  ListItem,
  List,
  Link,
  IconButton,
  Collapse,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PlusIcon, MinusIcon } from "./CustomIcons"; // Ensure the path is correct
import { Wrapper, Bag6, FooterIcons } from "./style";
import {
  AboutItems,
  EcosystemItems,
  SupportItems,
  MenuItem,
  LearnItems,
} from "./FooterMenuItems"; // Import EcosystemItems and SupportItems
import CookieConsentPopup from '../Cookies/CookieConsentPopup'; // Import your CookieConsentPopup component

const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // State for dropdowns
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleDropdownToggle = (section: string) => {
    setOpenDropdown(openDropdown === section ? null : section);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  // const handleClosePopup = () => {
  //   setIsPopupOpen(false);
  // };

  // Social Media Icons JSX
  const socialIcons = (
    <FooterIcons>
      <a
        href="https://discord.com/invite/JUHZNSuNtF"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Discord"
      >
        <i className="bi bi-discord"></i>
      </a>
      <a
        href="https://t.me/firestationcex"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Telegram"
      >
        <i className="bi bi-telegram"></i>
      </a>
      <a
        href="https://twitter.com/0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <i className="bi bi-twitter"></i>
      </a>
      <a
        href="https://www.facebook.com/0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <i className="bi bi-facebook"></i>
      </a>
      <a
        href="https://www.instagram.com/0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <i className="bi bi-instagram"></i>
      </a>
      <a
        href="https://www.threads.net/@0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Threads"
      >
        <i className="bi bi-threads"></i>
      </a>
      <a
        href="https://reddit.com/r/firestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Reddit"
      >
        <i className="bi bi-reddit"></i>
      </a>
      <a
        href="https://medium.com/@firestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Medium"
      >
        <i className="bi bi-medium"></i>
      </a>
      <a
        href="https://www.youtube.com/@0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
      >
        <i className="bi bi-youtube"></i>
      </a>
      <a
        href="https://tiktok.com/@0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="TikTok"
      >
        <i className="bi bi-tiktok"></i>
      </a>
      <a
        href="https://github.com/0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="GitHub"
      >
        <i className="bi bi-github"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/0xfirestation"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <i className="bi bi-linkedin"></i>
      </a>
    </FooterIcons>
  );

  return (
    <Wrapper>
      <Container>
        <Grid container spacing={2}>
          {/* Community Section */}
          {!isMobile && (
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Community</Typography>
              <FooterIcons>
                <a
                  href="https://discord.com/invite/JUHZNSuNtF"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Discord"
                >
                  <i className="bi bi-discord"></i>
                </a>
                <a
                  href="https://t.me/firestationcex"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Telegram"
                >
                  <i className="bi bi-telegram"></i>
                </a>
                <a
                  href="https://twitter.com/0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </FooterIcons>

              <FooterIcons>
                <a
                  href="https://www.instagram.com/0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="https://www.threads.net/@0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Threads"
                >
                  <i className="bi bi-threads"></i>
                </a>
                <a
                  href="https://reddit.com/r/firestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Reddit"
                >
                  <i className="bi bi-reddit"></i>
                </a>
                <a
                  href="https://medium.com/@firestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Medium"
                >
                  <i className="bi bi-medium"></i>
                </a>
              </FooterIcons>

              <FooterIcons>
                <a
                  href="https://www.youtube.com/@0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <i className="bi bi-youtube"></i>
                </a>
                <a
                  href="https://tiktok.com/@0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="TikTok"
                >
                  <i className="bi bi-tiktok"></i>
                </a>
                <a
                  href="https://github.com/0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="GitHub"
                >
                  <i className="bi bi-github"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/0xfirestation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </FooterIcons>
            </Grid>
          )}
          {isMobile && (
            <>
              <Grid item xs={12}>
                <Bag6>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    About Us
                    <IconButton
                      onClick={() => handleDropdownToggle("about")}
                      aria-label="toggle about section"
                      sx={{ ml: 1 }}
                    >
                      {openDropdown === "about" ? <MinusIcon /> : <PlusIcon />}
                    </IconButton>
                  </Typography>
                  <Collapse in={openDropdown === "about"}>
                    <List
                      disablePadding
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      {AboutItems.map((item: MenuItem) => (
                        <ListItem key={item.id} disablePadding>
                          <Link
                            href={item.href}
                            underline="none"
                            color="textSecondary"
                          >
                            {item.title}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Bag6>
              </Grid>

              <Grid item xs={12}>
                <Box className="bag6">
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Products
                    <IconButton
                      onClick={() => handleDropdownToggle("products")}
                      aria-label="toggle products section"
                      sx={{ ml: 1 }}
                    >
                      {openDropdown === "products" ? (
                        <MinusIcon />
                      ) : (
                        <PlusIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Collapse in={openDropdown === "products"}>
                    <List
                      disablePadding
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      {EcosystemItems.map((item) => (
                        <ListItem key={item.title} disablePadding>
                          <Link
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                            color="textSecondary"
                          >
                            {item.title}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              </Grid>

              {/* <Grid item xs={12}>
                <Box className="bag6">
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Learn
                    <IconButton
                      onClick={() => handleDropdownToggle("learn")}
                      aria-label="toggle support section"
                      sx={{ ml: 1 }}
                    >
                      {openDropdown === "learn" ? <MinusIcon /> : <PlusIcon />}
                    </IconButton>
                  </Typography>
                  <Collapse in={openDropdown === "learn"}>
                    <List
                      disablePadding
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      {LearnItems.map((item) => (
                        <ListItem key={item.title} disablePadding>
                          <Link
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                            color="textSecondary"
                          >
                            {item.title}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              </Grid> */}

              <Grid item xs={12}>
                <Box className="bag6">
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Support
                    <IconButton
                      onClick={() => handleDropdownToggle("support")}
                      aria-label="toggle support section"
                      sx={{ ml: 1 }}
                    >
                      {openDropdown === "support" ? (
                        <MinusIcon />
                      ) : (
                        <PlusIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Collapse in={openDropdown === "support"}>
                    <List
                      disablePadding
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      {SupportItems.map((item) => (
                        <ListItem key={item.title} disablePadding>
                          <Link
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                            color="textSecondary"
                          >
                            {item.title}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {socialIcons}
              </Grid>
            </>
          )}
          {!isMobile && (
            <>
              <Grid item md={2} xs={12}>
                <Bag6>
                  <Typography variant="h6">About Us</Typography>
                  <List disablePadding>
                    {AboutItems.map((item: MenuItem) => (
                      <ListItem key={item.id} disablePadding>
                        <Link
                          href={item.href}
                          underline="none"
                          color="textSecondary"
                        >
                          {item.title}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Bag6>
              </Grid>
              <Grid item md={2} xs={12}>
                <Bag6>
                  <Typography variant="h6">Products</Typography>
                  <List disablePadding>
                    {EcosystemItems.map((item) => (
                      <ListItem key={item.title} disablePadding>
                        <Link
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          color="textSecondary"
                        >
                          {item.title}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Bag6>
              </Grid>

              <Grid item md={2} xs={12}>
                <Bag6>
                  <Typography variant="h6">Learn</Typography>
                  <List disablePadding>
                    {LearnItems.map((item) => (
                      <ListItem key={item.title} disablePadding>
                        <Link
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          color="textSecondary"
                        >
                          {item.title}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Bag6>
              </Grid>

              <Grid item md={2} xs={12}>
                <Bag6>
                  <Typography variant="h6">Support</Typography>
                  <List disablePadding>
                    {SupportItems.map((item) => (
                      <ListItem key={item.title} disablePadding>
                        <Link
                          href={item.href}
                          underline="none"
                          color="textSecondary"
                        >
                          {item.title}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Bag6>
              </Grid>

              <Grid item md={4} xs={12}>
                {/* This Grid item is hidden on desktop, but visible on mobile */}
                {isMobile && (
                  <>
                    <Typography variant="h6">Community</Typography>
                    {socialIcons}
                  </>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex', // Enables flexbox layout
                justifyContent: 'center', // Centers the content horizontally
                alignItems: 'center', // Vertically aligns items in the center
                gap: 2, // Space between copyright and link
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: isMobile ? "0.7rem" : "0.8rem",
                  textAlign: "center",
                  margin: 0, // Remove default margin to avoid misalignment
                  // lineHeight: 1, // Ensure consistent line height
                }}
              >
                Fire Station© 2024
              </Typography>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleOpenPopup();
                }}
                style={{
                  cursor: 'pointer',
                  fontSize: isMobile ? "0.7rem" : "0.8rem",
                  textDecoration: 'none', // Remove underline
                  color: 'inherit', // Inherit color from parent
                  display: 'inline-flex', // Ensure the link aligns well with text
                  alignItems: 'center', // Aligns the link text vertically centered
                  lineHeight: 1, // Ensure consistent line height
                }}
              >
              <Typography
                variant="body1"
                style={{
                  fontSize: isMobile ? "0.7rem" : "0.8rem",
                  textAlign: "center",
                  margin: 0, // Remove default margin to avoid misalignment
                }}
              >
                Cookie Preference
              </Typography>
              </Link>
            </Box>
          </Grid>


        </Grid>

        {/* Cookie Consent Popup */}
        {isPopupOpen && <CookieConsentPopup />}
      </Container>
    </Wrapper>
  );
};

export default Footer;
