import React, { SyntheticEvent, useState } from "react";
import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PrivacyPolicy = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Navbar />
      <Box sx={{ padding: "3rem 0 2rem" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab
                      label="Fire Station Privacy Policy"
                      sx={{
                        color: "#000 !important",
                        fontSize: "12px !important",
                      }}
                      {...a11yProps(0)}
                    />
                    {/* <Tab
                      label="Fire Station Digital Wallet Privacy Policy"
                      sx={{ color: "#000 !important", fontSize: "12px !important" }}
                      {...a11yProps(1)}
                    /> */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Box sx={{ marginTop: "1rem" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        margin: "13px 0",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Privacy Notice - Fire Station
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        margin: "3rem 0 0",
                      }}
                    >
                      Last updated: 10 August, 2024
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "2rem 0 1rem 0" }}>
                    <Typography>
                      Please note that this privacy policy will regularly be
                      updated to reflect any changes in the way we handle your
                      personal data or any changes in applicable laws.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      This Privacy Policy describes how Fire Station (“we”,
                      “us”, “our”, “ourselves”) collects and processes your
                      personal information (“you”, “your”) through the Fire
                      Station websites and applications that reference this
                      Privacy Policy. By using the services provided by Fire
                      Station (“Fire Station Services”), you are consenting to
                      the collection, storage, processing, and transfer of your
                      personal information as described in this Privacy Policy.
                      Fire Station is a registered trademark of Fire Station.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Fire Station, registered under KVK number 60420235, with
                      the registered office at Schaikstraat 42, 1107TB
                      Amsterdam, The Netherlands, is the Data Controller for
                      personal information collected and processed in connection
                      with the provision of Fire Station Services.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      We firmly believe that where the above table states that
                      we rely on our legitimate interests for a given purpose,
                      this is not overridden by your interests, rights, or
                      freedoms. Therefore, if you wish to obtain further
                      information on this balancing test approach, please
                      contact{" "}
                      <a href="mailto:dpo@firestation.io">
                        dpo@firestation.io
                      </a>
                      .
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      We will not use your personal data for purposes that are
                      incompatible with the purposes of which you have been
                      informed unless it is required or authorized by law, or it
                      is in your own vital interest (for example, in case of a
                      medical emergency) to do so.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Sensitive Data</Typography>
                    <Typography>
                      The term “sensitive data” refers to the various categories
                      of personal data identified by data privacy laws or
                      regulations as requiring special treatment, including in
                      some circumstances the need to obtain explicit consent
                      from you. These categories include racial or ethnic
                      origin, political opinions, religious, philosophical or
                      other similar beliefs, membership of a trade union,
                      physical or mental health, biometric or genetic data,
                      sexual life or orientation, or criminal convictions and
                      offences (including information about suspected criminal
                      activities).
                    </Typography>
                    <Typography>
                      Due to the type of services provided, we might need to
                      process certain types of sensitive data. In the limited
                      cases where we do collect such data, we will do this in
                      accordance with data privacy laws and regulations and/or
                      ask for your consent.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="h6">
                      2. What About Cookies and Other Identifiers?
                    </Typography>
                    <Typography>
                      We use cookies and similar tools to enhance your user
                      experience, provide our services, and understand how
                      customers use our services so we can make improvements.
                      For more information about how we use cookies, please
                      visit our Cookie Policy. The cookie banner on your browser
                      will tell you how to accept or refuse cookies. For more
                      information on how you can update your cookie preferences,
                      please visit our{" "}
                      <a href="https://firestation.io/cookie-pc">
                        cookie preference centre
                      </a>
                      .
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      3. Does Fire Station Share My Personal Information?
                    </Typography>
                    <Typography>
                      Information about our users is an important part of our
                      business, and we are not in the business of selling our
                      users personal information to others. Fire Station shares
                      users personal information and Sensitive Personal Data
                      only as described below and with the subsidiaries or
                      affiliates of Fire Station that are either subject to this
                      Privacy Policy or follow practices at least as protective
                      as those described in this Privacy Policy.
                    </Typography>
                    <Typography>
                      Third party service providers: We employ other companies
                      and individuals to perform functions on our behalf.
                      Examples include analysing data, providing marketing
                      assistance, processing payments, transmitting content,
                      assessing and managing credit risk, and performing
                      identity verification and other KYC functions. These
                      third-party service providers have access to personal
                      information and Sensitive Personal Data needed to perform
                      their functions but may not use it for other purposes.
                      Further, they must process the personal information in
                      accordance with this Privacy Policy and as only permitted
                      by applicable data protection laws.
                    </Typography>
                    <Typography>
                      Business transfers: As we continue to develop our
                      business, we might sell or buy other businesses or
                      services. In such transactions, user information generally
                      is one of the transferred business assets but remains
                      subject to the promises made in any pre-existing Privacy
                      Policy. Also, in the unlikely event that Fire Station or
                      substantially all of its assets are acquired, user
                      information will be one of the transferred assets.
                    </Typography>
                    <Typography>
                      Protection of Fire Station and others: We release account
                      and other personal information when we believe such
                      release is appropriate to comply with the law or with our
                      regulatory obligations; enforce or apply our Terms of Use
                      and other agreements; or protect the rights, property, or
                      safety of Fire Station, our users, or others. This
                      includes exchanging information with other companies and
                      organisations for fraud protection and credit risk
                      reduction.
                    </Typography>
                    <Typography>
                      With your consent: Other than as set out above, you will
                      receive notice when personal information about you might
                      be shared with third parties, and you will have an
                      opportunity to choose not to share the information.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      For questions regarding the processing of your personal
                      data, please contact{" "}
                      <a href="mailto:dpo@firestation.io">
                        dpo@firestation.io
                      </a>
                      .
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      4. How Secure Is My Information?
                    </Typography>
                    <Typography>
                      We design our systems with your security and privacy in
                      mind. We work to protect the security of your personal
                      information during transmission by using encryption
                      protocols and software. We maintain physical, electronic,
                      and procedural safeguards in connection with the
                      collection, storage, and disclosure of your personal
                      information. Our security procedures mean that we may ask
                      you to verify your identity to protect you against
                      unauthorised access to your account password. We recommend
                      using a unique password for your Fire Station account that
                      is not used for other online accounts. Please make sure to
                      sign off when you finish using a shared computer.
                    </Typography>
                    <Typography>
                      Our systems are designed to ensure that your personal
                      information cannot be searched, stored, used, or
                      transferred without your express consent.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">5. Contact Us</Typography>
                    <Typography>
                      For more information about our privacy practices, if you
                      have questions, or if you would like to make a complaint,
                      please contact us by e-mail at{" "}
                      <a href="mailto:dpo@firestation.io">
                        dpo@firestation.io
                      </a>{" "}
                      or by mail using the details provided below:
                    </Typography>
                    <Typography>
                      Fire Station
                      <br />
                      Schaikstraat 42
                      <br />
                      1107TB Amsterdam
                      <br />
                      The Netherlands
                    </Typography>
                  </Box>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  {/* Placeholder for the second tab */}
                  <Typography>Item Two</Typography>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
