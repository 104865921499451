import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { cardData } from './cardData';

// Styled components for the cards and container
const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the left
    padding: theme.spacing(2),
    textAlign: 'left',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

const StyledIcon = styled('div')({
    marginLeft: '0.8rem', // Adds left margin to the icon
    display: 'flex',
    alignItems: 'center', // Vertically centers the icon
});

const TitleTypography = styled(Typography)({
    fontSize: '1.2rem', // Adjust as needed
    fontWeight: 'bold', // Make the title stand out
    marginBottom: '0.5rem', // Space between title and description
});

const DescriptionTypography = styled(Typography)({
    fontSize: '0.875rem', // Smaller size for description
    color: 'text.secondary',
});

const Ecosystem = () => {
    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gap={2}
            p={2}
            sx={{
                // Use the theme's breakpoints to adjust layout for mobile view
                '@media (max-width:600px)': {
                    gridTemplateColumns: '1fr', // Stack cards vertically on mobile
                },
            }}
        >
            {cardData.map((card, index) => (
                <StyledCard key={index}>
                    <StyledIcon>
                        {card.icon}
                    </StyledIcon>
                    <CardContent>
                        <TitleTypography>
                            {card.label}
                        </TitleTypography>
                        <DescriptionTypography>
                            {card.description}
                        </DescriptionTypography>
                    </CardContent>
                </StyledCard>
            ))}
        </Box>
    );
};

export default Ecosystem;
