import React from "react";
import { Box, Grid, styled } from "@mui/material";

// Define a styled component for the image container
const ImageContainer = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const MobileImage = () => {
  return (
    <Grid item xs={12} md={5}>
      <ImageContainer>
        <img
          src="./images/3d-firechain.png"
          style={{ width: "100%" }}
          alt="Firechain"
        />
      </ImageContainer>
    </Grid>
  );
};

export default MobileImage;
