import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Button,
  styled,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinkIcon from "@mui/icons-material/Link";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WalletIcon from "@mui/icons-material/Wallet";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Link } from "react-router-dom";

const drawerWidth = "100%";

// Adjusted Logo for responsive design
const Logo = styled("img")(({ theme }) => ({
  width: "150px",
  [theme.breakpoints.down("sm")]: {
    width: "120px", // Adjust the logo width for smaller screens
  },
}));

const NavLink = styled(Link)({
  textDecoration: "none",
  color: "#000",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  fontSize: "14px",
  fontWeight: "600",
  "&:hover": {
    textDecoration: "underline",
  },
});

const StyledButton = styled(Button)({
  backgroundColor: "#920000",
  color: "#fff",
  height: "100%",
  textTransform: "none",
  borderRadius: "10px",
  padding: "5px 10px",
  "&:hover": {
    backgroundColor: "#c81010",
  },
  "&:active": {
    backgroundColor: "#8a0000",
  },
  "&:focus": {
    outline: "none",
  },
});

const MenuColumn = styled(Box)({
  flex: "1",
  padding: "10px",
  boxSizing: "border-box",
  maxWidth: "50%",
  display: "flex",
  flexDirection: "column", // Ensure items stack vertically
});

const MenuItemStyled = styled(MenuItem)({
  display: "flex",
  alignItems: "center", // Align items to the center
  padding: "8px 16px",
});

const IconTextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginLeft: "10px",
  maxWidth: "calc(100% - 40px)", // Adjust based on icon size and margin
});

const TitleText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
});

const DescriptionText = styled(Typography)({
  fontSize: "12px",
  color: "#666",
  maxWidth: "100%", // Ensure full width of container
  overflowWrap: "break-word",
  wordBreak: "break-word",
  lineHeight: 1.5,
  whiteSpace: "normal", // Ensure text wraps
});

const Navbar = (props: { window?: () => Window }) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          padding: "1rem 0",
        }}
      >
        <NavLink
          to="/login"
          style={{
            textDecoration: "none",
            color: "#000",
            display: "flex",
            alignItems: "center",
          }}
        >
          Log in
          <img
            src="/images/login.png"
            alt="Log in"
            style={{
              marginLeft: "5px",
              height: "16px",
              width: "16px",
            }}
          />
        </NavLink>
        <StyledButton href="/register">
          <img
            src="/images/login-gift.png"
            alt="Sign up"
            style={{
              marginRight: "10px",
              height: "16px",
              width: "16px",
            }}
          />
          Sign up
        </StyledButton>
      </Box>
      <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 0,
          m: 0,
        }}
      >
        {/* <ListItem>
          <NavLink to="/about-us">About</NavLink>
        </ListItem> */}
        <ListItem>
          <NavLink to="/partner-with-us">Partner with us</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/blog">Blog</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/markets">Markets</NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink to="/">Trade</NavLink>
        </ListItem> */}
        <ListItem>
          <NavLink to="https://donaswap.io" target="_blank">
            DEX
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="https://firemask.io" target="_blank">
            Wallet
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="https://firesea.io" target="_blank">
            Marketplace
          </NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink to="/">
            More
          </NavLink>
        </ListItem> */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex", bgcolor: "#fff" }}>
        <AppBar
          component="nav"
          sx={{ bgcolor: "#fff", boxShadow: "none", position: "static" }}
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link to="/">
                  <Logo
                    src="/images/firestation-wordmark-red.png"
                    alt="Fire Station Logo"
                  />
                </Link>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "row",
                    alignItems: "center",
                    ml: 2,
                  }}
                >
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      p: 0,
                      m: 0,
                    }}
                  >
                    {/* <ListItem>
                      <NavLink to="/trade">Trade</NavLink>
                    </ListItem> */}

                    <ListItem>
                      <NavLink to="/partner-with-us">Partner with us</NavLink>
                    </ListItem>
{/* 
                    <ListItem>
                      <NavLink to="/markets">Markets</NavLink>
                    </ListItem> */}

                    <ListItem>
                      <NavLink to="/Blog">Blog</NavLink>
                    </ListItem>

                    {/* More dropdown menu */}
                    <ListItem
                      button
                      aria-controls="more-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0",
                        ml: 2,
                      }}
                    >
                      <NavLink
                        to="#"
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        More
                      </NavLink>
                      <ArrowDropDownIcon sx={{ color: "#000" }} />
                    </ListItem>
                    <Menu
                      id="more-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          minWidth: "300px",
                          maxWidth: "40vw",
                          display: "flex",
                          flexDirection: "row", // Display columns side by side
                          p: 0,
                          m: 0,
                        },
                      }}
                    >
                      <Box sx={{ display: "flex", width: "100%" }}>
                        <MenuColumn>
                          <MenuItemStyled onClick={handleClose}>
                            <ListItemIcon sx={{ minWidth: "40px", mr: 1 }}>
                              <SwapHorizIcon />
                            </ListItemIcon>
                            <IconTextWrapper>
                              <TitleText>
                                <NavLink
                                  to="https://donaswap.io"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  DonaSwap
                                </NavLink>
                              </TitleText>
                              <DescriptionText variant="body2">
                                The primary multi-chain decentralized exchange
                                of Fire Station
                              </DescriptionText>
                            </IconTextWrapper>
                          </MenuItemStyled>
                          <MenuItemStyled onClick={handleClose}>
                            <ListItemIcon sx={{ minWidth: "40px", mr: 1 }}>
                              <WalletIcon />
                            </ListItemIcon>
                            <IconTextWrapper>
                              <TitleText>
                                <NavLink
                                  to="https://firemask.io"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  FireMask
                                </NavLink>
                              </TitleText>
                              <DescriptionText variant="body2">
                                A new wallet tuned to the max. It&apos;s a better way
                                to buy, store, send and swap tokens.
                              </DescriptionText>
                            </IconTextWrapper>
                          </MenuItemStyled>
                          <MenuItemStyled onClick={handleClose}>
                            <ListItemIcon sx={{ minWidth: "40px", mr: 1 }}>
                              <StorefrontIcon />
                            </ListItemIcon>
                            <IconTextWrapper>
                              <TitleText>
                                <NavLink
                                  to="https://firesea.io"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  FireSea
                                </NavLink>
                              </TitleText>
                              <DescriptionText variant="body2">
                                A new multichain NFT marketplace. Buy, sell, &
                                discover the internet of goods.
                              </DescriptionText>
                            </IconTextWrapper>
                          </MenuItemStyled>
                        </MenuColumn>
                        <MenuColumn>
                          <MenuItemStyled onClick={handleClose}>
                            <ListItemIcon sx={{ minWidth: "40px", mr: 1 }}>
                              <LinkIcon />
                            </ListItemIcon>
                            <IconTextWrapper>
                              <TitleText>
                                <NavLink
                                  to="https://thefirechain.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  FireChain
                                </NavLink>
                              </TitleText>
                              <DescriptionText variant="body2">
                                Open source platform to write and distribute
                                decentralized applications.
                              </DescriptionText>
                            </IconTextWrapper>
                          </MenuItemStyled>
                          <MenuItemStyled onClick={handleClose}>
                            <ListItemIcon sx={{ minWidth: "40px", mr: 1 }}>
                              <ManageSearchIcon />
                            </ListItemIcon>
                            <IconTextWrapper>
                              <TitleText>
                                <NavLink
                                  to="https://firescan.io"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  FireScan
                                </NavLink>
                              </TitleText>
                              <DescriptionText variant="body2">
                                Network explorer for Firechain networks
                              </DescriptionText>
                            </IconTextWrapper>
                          </MenuItemStyled>
                        </MenuColumn>
                      </Box>
                    </Menu>
                  </List>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <NavLink
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 20px",
                  }}
                >
                  Log in
                  <img
                    src="/images/login.png"
                    alt="Log in"
                    style={{
                      marginLeft: "5px",
                      height: "16px",
                      width: "16px",
                    }}
                  />
                </NavLink>
                <StyledButton href="/register">
                  <img
                    src="/images/login-gift.png"
                    alt="Sign up"
                    style={{
                      marginRight: "10px",
                      height: "16px",
                      width: "16px",
                    }}
                  />
                  Sign up
                </StyledButton>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  alignItems: "center",
                }}
              >
                {!mobileOpen && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ ml: 2, color: "#000" }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}

              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "10px",
              paddingTop: "10px",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="close drawer"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Navbar;
