import React from "react";
import { Box, Typography, Grid, styled } from "@mui/material";

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

const ImageBox = styled(Box)(({ theme }) => ({
    height: "280px",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: theme.palette.divider,
    marginBottom: "1rem",
    backgroundSize: "cover", // Ensure the background image covers the container
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
}));

const Work = () => {
    return (
        <StyledBox>
            <StyledTypography variant="h4">
                Work With Us
            </StyledTypography>
            <StyledParagraph>
                Select a team most relevant to your interests and experience to view job openings.
            </StyledParagraph>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Box display="flex" flexDirection="column">
                        <ImageBox
                            sx={{ backgroundImage: `url('/images/3d-firechain.png')` }}
                        />
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                            Thrive with Fire Station
                        </Typography>
                        <StyledParagraph>
                            What&apos;s our secret to success? Our people. Be united by a common DNA of talented, hardworking, and passionate individuals in a world-renowned team.
                        </StyledParagraph>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box display="flex" flexDirection="column">
                        <ImageBox
                            sx={{ backgroundImage: `url('/images/3d-firechain.png')` }}
                        />
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                            Inspire through Innovation
                        </Typography>
                        <StyledParagraph>
                            Embrace the future with challenges. Tackle accelerating the adoption of cryptocurrency, blockchain, and distributed ledger technology with the leading company in the crypto space.
                        </StyledParagraph>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box display="flex" flexDirection="column">
                        <ImageBox
                            sx={{ backgroundImage: `url('/images/3d-firechain.png')` }}
                        />
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                            Bridge the Gap at Fire Station
                        </Typography>
                        <StyledParagraph>
                            Transform our products and services with expertise and be recognized for success. Connect in an expanding environment that rewards personal growth.
                        </StyledParagraph>
                    </Box>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

export default Work;
