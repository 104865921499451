import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
    other?: object;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const RiskWarning = () => {
    const [value] = useState(0);

    return (
        <>
            <Navbar />
            <Box
                sx={{
                    padding: "3rem 0 2rem",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "50vh",
                }}
            >
                <Container
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Box>
                                <TabPanel value={value} index={0}>
                                    <Box
                                        sx={{
                                            marginTop: "1rem",
                                            "& h5": {
                                                margin: "13px 0",
                                                fontSize: "1rem",
                                                fontWeight: "bold",
                                            },
                                            "& h6": {
                                                fontSize: "1.6rem",
                                                fontWeight: "bold",
                                                margin: "3rem 0 0",
                                            },
                                        }}
                                    >
                                        <Typography variant="h5">General Risk Warning</Typography>
                                        <Typography variant="h6">
                                            Last updated: 12 September, 2024
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: "2rem",
                                            "& p": {
                                                margin: "1rem 0",
                                            },
                                        }}
                                    >
                                        <Typography paragraph>
                                            Fire Station may run promotional campaigns from time to time that are sponsored by partner projects including short-term Simple Earn product offerings with higher APRs to benefit users. These higher rates may be temporary and you should consider whether the relevant offer still meets your needs once the interest rate reverts to its normal level when the promotion period expires.
                                        </Typography>
                                        <Typography paragraph>
                                            Although Fire Station takes all reasonable precautions to minimize the risks described in this Risk Warning and to safeguard the return of Simple Earn Assets to users in accordance with the Simple Earn Terms, in extreme and exceptional circumstances Fire Station may not have sufficient assets readily available to return Simple Earn Assets to users in a timely manner. This could result in users losing some or all of their Simple Earn Assets or experiencing a delay in the return of all or some Simple Earn Assets to users. These exceptional circumstances could arise, for example, following a catastrophic hack or technical failure, a significant number of borrowers to whom loans have been granted under Margin and Loan products defaulting at the same time, depletion of the insurance fund, extreme market volatility, network delays or disruptions, systemic risk or other black swan events that affect the cryptocurrency market as a whole.
                                        </Typography>
                                        <Typography paragraph>
                                            ETH Staking Risks Staking involves a risk of protocol penalties, also known as “slashing”, designed to disincentivize dishonest and malicious validator activity, where some or all of a validator’s staked Digital Assets are forfeited as a penalty. Slashing can occur due to errors in smart contracts, misbehaviour of validators, or mistakes during block production. Slashing is very rare, with only 0.04% of the over 500,000 Ethereum validators ever experiencing a slashing event, according to data from February 2023. While Fire Station takes all reasonable precautions to avoid slashing and will wish to compensate users as far as possible (acting at our discretion) to replace Digital Assets lost to slashing penalties if they do occur, so that you should not experience any loss even in the event of slashing, in some rare situations it is possible you could lose some or all of your Staked Assets. This would include situations where slashing is the result of a hack, your own actions or other extreme events.
                                        </Typography>
                                        <Typography paragraph>
                                            Staking Rewards are calculated based on the Variable Reward Rate and each user’s amount of Staked Assets. The Variable Reward Rate is determined by Fire Station in its discretion, based on the On-Chain Rewards and the commission fee charged by Fire Station, each of which may change from time to time. All On-Chain Rewards will be retained by Fire Station and participants in ETH Staking will only receive the Staking Rewards, which may be more or less than the amount of On-Chain Rewards.
                                        </Typography>
                                        <Typography paragraph>
                                            Users may not be able to redeem the entirety of their Staked Assets in a single redemption request and may be required to submit multiple redemption requests over a period of time. Due to the processing limitations on the Ethereum network, redemptions are subject to the Redemption Pool and each user’s Daily Redemption Quota. The Redemption Pool is the total amount of ETH that Fire Station has unstaked and is available each day to satisfy requests for the redemption of Staked Assets. The Daily Redemption Quota is the maximum amount of Staked Assets that each user can request for redemption each day. Both the Redemption Pool, the Daily Redemption Quota and anticipated processing may change from time to time. You can also check the delivery date and status for submitted redemption requests here. WBETH and BETH under the redemption process will not generate any ETH Staking rewards.
                                        </Typography>
                                        <Typography paragraph>
                                            Fire Station does not guarantee that you will receive Staking Rewards at the Variable Staking Rate shown at the time you Subscribe. The Variable Staking Rate will change from time to time and may fluctuate up or down from the rate applicable at the time of Subscription. Any representation concerning possible returns at the time you Subscribe is an estimate only and not guaranteed; and based on the Variable Staking Rate at the relevant time, which may change.
                                        </Typography>
                                        <Typography paragraph>
                                            Redeeming Staked Assets takes time. Redeeming BETH or WBETH for ETH is generally expected to take approximately six (6) days once a redemption request is successfully submitted, however the actual processing time may vary and could be significantly longer. Delivery of ETH following a redemption request or WBETH following a conversion request could be subject to additional delay in various circumstances including, without limitation, extreme market volatility, network delays and outages, validator or protocol failure, a significant number of simultaneous instructions to convert ETH to WBETH or WBETH to ETH from other Fire Station users, or other unanticipated events. There is no guaranteed timeframe for completion of redemption or conversion requests. Because cryptocurrency prices can be highly volatile, there is a risk that the market price of ETH could be significantly higher or lower by the time the redemption request is completed and users will be unable to dispose of their Staked Assets while a redemption request is being processed. In addition, in rare or extreme situations it is possible that the value of your Staked Assets or Staking Rewards may drop to zero.
                                        </Typography>
                                        <Typography paragraph>
                                            Fire Station does not guarantee uninterrupted or error-free operation of the ETH Staking Services or that it will correct all defects or prevent disruptions or unauthorized access. Fire Station may suspend or discontinue the ETH Staking Services in its sole discretion at any time. If there is any disruption, suspension or discontinuance of the ETH Staking Services, any Staked Assets may stop generating Staking Rewards and you may not receive any (and you may forfeit all or some) Staking Rewards. Fire Station has no liability or responsibility for and expressly disclaims any loss, liability or damage that users may incur directly or indirectly in connection with the ETH Staking Services, including without limitation loss, liability or damage arising directly or indirectly from interruptions, errors or defects in the ETH Staking Services, third party disruptions of or unauthorized access to the ETH Staking Services and any suspension or discontinuance of the ETH Staking Services.
                                        </Typography>
                                        <Typography paragraph>
                                            Risk of trading using leverage Trading using leverage entails significant risk and it is important that you fully understand the risks involved in trading Digital Assets using leverage.
                                        </Typography>
                                        <Typography paragraph>
                                            A relatively small market movement will have a proportionately larger impact on the leveraged funds you have deposited or will have to deposit; this may work against you as well as for you. You may sustain a total loss of initial margin funds and, in exceptional circumstances, any additional funds deposited with Fire Station to maintain your position. If the market moves against your position or margin levels are increased, you may be called upon to pay substantial additional funds on short notice to maintain your position. If you fail to comply with a request for additional funds within the time prescribed, your position may be liquidated at a loss and you may, in exceptional circumstances, be liable for any resulting deficit.
                                        </Typography>
                                        <Typography paragraph>
                                            Risk of trading Futures Futures are complex leveraged products and may not be suitable for inexperienced investors. Before investing, investors must understand the nature and accept the risks of futures products, including extreme price volatility of Digital Asset Futures and the risk that the total loss of the investment is possible.
                                        </Typography>
                                        <Typography paragraph>
                                            High volatility and uncertainty: The volatility and uncertainty in the market may impact your ability to trade and/or hedge, and may result in financial loss.
                                        </Typography>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Typography>Item Two</Typography>
                                </TabPanel>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    );
};

export default RiskWarning;
