import React, { useState } from "react";
import { Box, Typography, Grid, styled } from "@mui/material";

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

const ProfileImage = styled('img')(({ theme }) => ({
    borderRadius: '50%',
    width: '100px',  // Adjust size for larger screens
    height: '100px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
        width: '80px',   // Small screens
        height: '80px',
    },
}));

const ViewMoreLink = styled(Typography)({
    color: '#920000',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '1rem',
});

const Founders = () => {
    const [showMoreGino, setShowMoreGino] = useState(false);
    const [showMoreKryptonacci, setShowMoreKryptonacci] = useState(false);

    const toggleShowMoreGino = () => {
        setShowMoreGino(!showMoreGino);
    };

    const toggleShowMoreKryptonacci = () => {
        setShowMoreKryptonacci(!showMoreKryptonacci);
    };

    return (
        <StyledBox>
            <StyledTypography variant="h3">
                Our Founders
            </StyledTypography>
            <Grid container spacing={3} alignItems="center">

                {/* Left Column: Profile Image and Titles */}
                <Grid item xs={12} md={3}>  {/* Full width on small screens, 4 columns on medium and large */}
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ProfileImage src="/images/founders/ginofelter.png" alt="Gino Felter" />

                        <Box mt={2} textAlign="center"> {/* Adjust spacing between image and titles */}
                            <Typography sx={{ fontSize: '0.8rem', fontWeight: '600' }}>  {/* Custom size */}
                                GF (Gino &quot;Kryptstein&quot; Felter)
                            </Typography>
                            {/* Custom text size for the title */}
                            <Typography variant="body2" sx={{ fontSize: '1rem', color: 'text.secondary' }}>
                                Founder & CEO
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* Right Column: Description and Button */}
                <Grid item xs={12} md={9}> {/* Full width on small screens, 8 columns on medium and large */}
                    <StyledParagraph>
                        {showMoreGino ? (
                            <>
                                Gino Felter is a visionary entrepreneur and the driving force behind Fire Station,
                                a groundbreaking blockchain ecosystem. Since launching Fire Station,
                                Gino has rapidly established it as a prominent player in the digital asset space.
                                Under his leadership, Fire Station has evolved into a multifaceted platform,
                                offering a comprehensive suite of products including a state-of-the-art digital
                                asset exchange and cutting-edge blockchain solutions.
                                Gino&apos;s journey began with humble origins before he pursued higher education in Computer Science.
                                His early career saw him excel in various technology-driven roles,
                                eventually leading him to become a pivotal figure in the blockchain industry.
                                Before founding Fire Station, Gino held significant positions at leading tech firms,
                                where he honed his expertise in blockchain technology and digital assets.
                                With a commitment to innovation and a passion for transforming the digital landscape,
                                Gino Felter continues to drive Fire Station forward,
                                aiming to redefine the future of blockchain technology.
                            </>
                        ) : (
                            <>
                                Gino Felter is a visionary entrepreneur and the driving force behind Fire Station,
                                a groundbreaking blockchain ecosystem. Since launching Fire Station,
                                Gino has rapidly established it as a prominent player in the digital asset space.
                                Under his leadership, Fire Station has evolved into a multifaceted platform,
                                offering a comprehensive suite of products including a state-of-the-art digital
                                asset exchange and cutting-edge blockchain solutions.
                            </>
                        )}
                        <ViewMoreLink onClick={toggleShowMoreGino}>
                            {showMoreGino ? "Show Less" : "View More"}
                        </ViewMoreLink>
                    </StyledParagraph>
                </Grid>

                {/* Left Column: Profile Image and Titles */}
                <Grid item xs={12} md={9}>  {/* Full width on small screens, 8 columns on medium and large */}
                    <StyledParagraph>
                        {showMoreKryptonacci ? (
                            <>
                                Kryptonacci is an influential figure in the blockchain space, renowned for his innovative contributions
                                to the industry. As a key player at Fire Station, Kryptonacci has played a pivotal role in shaping
                                the company&apos;s vision and growth. With a background in technology and a passion for blockchain solutions,
                                he has been instrumental in driving the development of advanced digital asset technologies and
                                establishing Fire Station as a leader in the field.
                            </>
                        ) : (
                            <>
                                Kryptonacci is an influential figure in the blockchain space, renowned for his innovative contributions
                                to the industry. As a key player at Fire Station, Kryptonacci has played a pivotal role in shaping
                                the company&apos;s vision and growth.
                            </>
                        )}
                        <ViewMoreLink onClick={toggleShowMoreKryptonacci}>
                            {showMoreKryptonacci ? "Show Less" : "View More"}
                        </ViewMoreLink>
                    </StyledParagraph>
                </Grid>

                {/* Right Column: Profile Image and Titles */}
                <Grid item xs={12} md={3}>  {/* Full width on small screens, 4 columns on medium and large */}
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ProfileImage src="/images/founders/kryptonacci.png" alt="Kryptonacci" />

                        <Box mt={2} textAlign="center"> {/* Adjust spacing between image and titles */}
                            <Typography sx={{ fontSize: '0.8rem', fontWeight: '600' }}>  {/* Custom size */}
                                Kryptonacci
                            </Typography>
                            {/* Custom text size for the title */}
                            <Typography variant="body2" sx={{ fontSize: '1rem', color: 'text.secondary' }}>
                                Founder
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </StyledBox>
    );
};

export default Founders;
