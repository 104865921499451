import React, { useState } from 'react';
import { Box, Typography, Button, Modal, Checkbox, FormControlLabel, Collapse, IconButton, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const CookieOption = ({
    checked,
    onChange,
    label,
    description,
    labelFontSize = '0.8rem', // Default font size if not provided
    labelFontWeight = 600, // Default font size if not provided
    isOpen,
    onToggle,
}: {
    checked: boolean;
    onChange?: () => void;
    label: string;
    description: string;
    labelFontSize?: string; // Optional prop for custom font size
    labelFontWeight?: number; // Optional prop for custom font size
    isOpen: boolean;
    onToggle: () => void;
}) => {
    const theme = useTheme(); // Get the theme to use breakpoints

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                onClick={onToggle}
            >
                <IconButton size="small">
                    <AddIcon />
                </IconButton>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={onChange} />}
                    label={label}
                    labelPlacement="start"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        '& .MuiFormControlLabel-label': {
                            fontSize: labelFontSize,
                            fontWeight: labelFontWeight,
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '0.7rem',
                            },
                        },
                    }}
                />
            </Box>
            <Collapse in={isOpen}>
                <Box sx={{ mt: 1, pl: 3 }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.75rem' } }}>
                        {description}
                    </Typography>
                </Box>
            </Collapse>
        </Box>
    );
};

const CookieConsentPopup: React.FC = () => {
    const [open, setOpen] = useState(true);
    const [strictlyNecessary] = useState(true);
    const [functionalCookies, setFunctionalCookies] = useState(true);
    const [performanceCookies, setPerformanceCookies] = useState(true);
    const [targetingCookies, setTargetingCookies] = useState(false);
    const [openTab, setOpenTab] = useState<string | null>(null); // State for tracking open tab

    const handleClose = () => setOpen(false);

    const handleToggle = (tab: string) => {
        setOpenTab(openTab === tab ? null : tab); // Toggle tab
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="cookie-consent-title"
            aria-describedby="cookie-consent-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1300, // Ensures the modal stays above other content
                    width: {
                        xs: '80vw', // Mobile view
                        sm: '80vw', // Tablet view
                        md: '500px', // Desktop view
                    },
                    maxWidth: '500px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    overflowX: 'hidden',
                    mx: 'auto',
                }}
            >
                <a href="/">
                    <Box
                        component="img"
                        src="/images/firestation-wordmark-red.png"
                        alt="Fire Station Logo"
                        sx={{
                            width: {
                                xs: "100px", // Smaller logo for mobile
                                sm: "130px",
                                md: "200px", // Default logo size for larger screens
                            },
                            height: "auto",
                            marginBottom: "1rem",
                        }}
                    />
                </a>
                <Box
                    sx={{
                        flex: 1, // Allow this box to grow and fill available space
                        overflowY: 'auto', // Enable vertical scrolling
                        maxHeight: '400px', // Set max height to control scroll area
                        overflowX: 'hidden', // Ensure no horizontal scrolling
                        pr: 1, // Ensure right padding to make space for the scrollbar
                        mb: 2, // Ensure bottom margin
                    }}
                >
                    <Typography
                        id="cookie-consent-title"
                        variant="h5"
                        component="h2"
                        sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} // Custom font size for title
                        gutterBottom
                    >
                        Privacy Preference Center
                    </Typography>
                    <Typography
                        id="cookie-consent-description"
                        variant="body1"
                        sx={{
                            fontSize: {
                                xs: '0.75rem', // Smaller font for mobile
                                sm: '0.85rem',
                                md: '0.9rem',
                            },
                            mb: 2,
                        }}
                    >
                        We use cookies to collect data which improves your experience while you navigate through the website. Because we respect your right to privacy, you can choose not to allow some types of cookies. The information does not usually directly identify you but it might be about you, your preferences or your device and is mostly used to improve the site making the site work as you expect it to whilst giving you a personalised experience. Of course, we do all this with your consent and in complete transparency. The cookies that are categorized as &quot;Strictly Necessary&quot; are on by default and are stored on your browser as they are essential for the working of basic functionalities and security of the website. You can choose to enable or disable some or all of the additional cookies if they are present, but disabling some of them may affect your browsing experience.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                        <CookieOption
                            checked={strictlyNecessary}
                            label="Strictly Necessary Cookies (Always Active)"
                            description="These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information."
                            isOpen={openTab === 'strictlyNecessary'}
                            onToggle={() => handleToggle('strictlyNecessary')}
                        />
                        <CookieOption
                            checked={functionalCookies}
                            onChange={() => setFunctionalCookies(!functionalCookies)}
                            label="Functional Cookies"
                            description="These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly."
                            isOpen={openTab === 'functionalCookies'}
                            onToggle={() => handleToggle('functionalCookies')}
                        />
                        <CookieOption
                            checked={targetingCookies}
                            onChange={() => setTargetingCookies(!targetingCookies)}
                            label="Targeting Cookies"
                            description="Targeting cookies may be set through our site by ourselves and our advertising partners. First parties and third parties will use them to build a profile of your interests based on the browsing information they collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not allow these cookies you will still see basic advertising on your browser that is generic and not based on your interests"
                            isOpen={openTab === 'targetingCookies'}
                            onToggle={() => handleToggle('targetingCookies')}
                        />
                        <CookieOption
                            checked={performanceCookies}
                            onChange={() => setPerformanceCookies(!performanceCookies)}
                            label="Performance Cookies"
                            description="These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance."
                            isOpen={openTab === 'performanceCookies'}
                            onToggle={() => handleToggle('performanceCookies')}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#920000',
                            '&:hover': { backgroundColor: '#720000' },
                            fontSize: {
                                xs: '0.6rem', // Smaller button text for mobile
                                sm: '0.85rem',
                                md: '0.8rem',
                            },
                            padding: {
                                xs: '6px 16px', // Smaller padding for mobile
                                sm: '8px 20px',
                                md: '10px 24px',
                            },
                        }}
                    >
                        Accept All
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{
                            color: '#920000',
                            borderColor: '#920000',
                            '&:hover': { backgroundColor: 'rgba(146, 0, 0, 0.1)', borderColor: '#920000' },
                            fontSize: {
                                xs: '0.6rem', // Smaller button text for mobile
                                sm: '0.85rem',
                                md: '0.8rem',
                            },
                            padding: {
                                xs: '6px 16px', // Smaller padding for mobile
                                sm: '8px 20px',
                                md: '10px 24px',
                            },
                        }}
                    >
                        Save Preferences
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CookieConsentPopup;
