import React from "react";
import { Box, Typography, useMediaQuery, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Define a styled component for the logo image
const LogoImage = styled("img")({
  filter: "grayscale(50%)",
  maxWidth: "100%",
  borderRadius: "10px",
  height: 48,
  width: 48,
  transition: "transform 0.3s ease-in-out",
  display: "block",
  margin: "0 auto", // Center image horizontally
});

const LogoContainer = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  "&:hover": {
    "& img": {
      transform: "scale(1.1)",
    },
    "& .title": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
});

const TitleOverlay = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "#fff",
  textAlign: "center",
  padding: "5px 0",
  borderRadius: "0 0 10px 10px",
  opacity: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
  transform: "translateY(10px)",
});

// Define TypeScript type for logo image
interface LogoImageType {
  src: string;
  title: string;
  link: string;
}

const logoImages: LogoImageType[] = [
  {
    src: "/images/partners/cloudvisor.png",
    title: "Cloudvisor",
    link: "https://cloudvisor.co/",
  },
  { src: "/images/partners/aws.png", title: "AWS", link: "https://aws.amazon.com/" },
  {
    src: "/images/partners/digitalocean.png",
    title: "DigitalOcean",
    link: "https://www.digitalocean.com/",
  },
  {
    src: "/images/partners/google.png",
    title: "Google Cloud",
    link: "https://cloud.google.com/",
  },
  {
    src: "/images/partners/microsoft.png",
    title: "Microsoft",
    link: "https://microsoft.com/",
  },
  {
    src: "/images/partners/cloudflare.png",
    title: "Cloudflare",
    link: "https://cloudflare.com/",
  },
  {
    src: "/images/partners/graph.png",
    title: "The Graph",
    link: "https://thegraph.com/",
  },
  {
    src: "/images/partners/fireblocks.png",
    title: "Fireblocks",
    link: "https://www.fireblocks.com/",
  },
  {
    src: "/images/partners/quicknode.png",
    title: "QuickNode",
    link: "https://quicknode.com/",
  },
  {
    src: "/images/partners/amplitude.png",
    title: "Amplitude",
    link: "https://amplitude.com/",
  },
  {
    src: "/images/partners/firebase.png",
    title: "Firebase",
    link: "https://firebase.google.com/",
  },
  {
    src: "/images/partners/azure.png",
    title: "Azure",
    link: "https://azure.microsoft.com/",
  },
  { src: "/images/partners/miro.png", title: "Miro", link: "https://miro.com/" },
  {
    src: "/images/partners/airtable.png",
    title: "Airtable",
    link: "https://airtable.com/",
  },
  {
    src: "/images/partners/bs.jpg",
    title: "Blockscout",
    link: "https://www.blockscout.com/",
  },
  {
    src: "/images/partners/defillama.jpg",
    title: "DefiLlama",
    link: "https://defillama.com/",
  },
  {
    src: "/images/partners/cmc.png",
    title: "CoinMarketCap",
    link: "https://coinmarketcap.com/",
  },
  {
    src: "/images/partners/coingecko.png",
    title: "CoinGecko",
    link: "https://coingecko.com",
  },
];

const Partners: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 18, // Number of logos to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000, // Speed in milliseconds (10 seconds)
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 12 } },
      { breakpoint: 1280, settings: { slidesToShow: 10 } },
      { breakpoint: 1024, settings: { slidesToShow: 8 } },
      { breakpoint: 768, settings: { slidesToShow: 6 } },
      { breakpoint: 480, settings: { slidesToShow: 4 } },
      { breakpoint: 400, settings: { slidesToShow: 3 } },
    ],
  };

  return (
    <Box sx={{ marginBottom: 5, px: 10 }}>
      <Typography
        variant={isMobile ? "h6" : "h4"}
        component="h2"
        align="center"
        fontWeight="bold"
        marginBottom={3}
      >
        Partners & Friends
      </Typography>
      <Slider {...settings}>
        {logoImages.map((logo, index) => (
          <LogoContainer key={index}>
            <LogoImage src={logo.src} alt={`Logo ${index + 1}`} />
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <TitleOverlay className="title">{logo.title}</TitleOverlay>
            </a>
          </LogoContainer>
        ))}
      </Slider>
    </Box>
  );
};

export default Partners;
