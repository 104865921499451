import React from "react";
import { Box, Typography, styled } from "@mui/material";

// Define styled components using MUI's styled API
const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: "left", // Ensure text is aligned to the left
  margin: "2rem 0", // Add vertical spacing
  [theme.breakpoints.down("sm")]: {
    padding: "0 1rem", // Adjust padding for smaller screens
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "1rem", // Add spacing below the title
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.8rem", // Adjust font size for smaller screens
  },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  width: "100%", // Set width to 100% of the container
  maxWidth: "500px", // Set a maximum width for readability
  margin: "0", // Remove automatic margins to align with title
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem", // Adjust font size for smaller screens
  },
}));

const HeaderTitle = () => {
  return (
    <StyledBox>
      <StyledTypography variant="h3">
        A New Digital Asset Exchange
      </StyledTypography>
      <StyledParagraph>
        Coming soon: Fire Station innovative trading platform, designed to give
        you the freedom to trade anytime, anywhere. Currently in development,
        this platform will enable seamless market access and on-the-go trade
        execution, empowering you to stay connected and make informed decisions
        from anywhere.
      </StyledParagraph>
    </StyledBox>
  );
};

export default HeaderTitle;
