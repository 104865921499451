import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  styled,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: "400px",
  width: "100%",
  padding: "2rem",
  border: "1px solid #e0e0e0",
  borderRadius: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "80%",
    padding: "1rem",
    margin: "0 1rem",
  },
  "& h5": {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "& p": {
    color: "rgba(71, 77, 87, 0.8)",
    fontSize: "0.9rem",
  },
  "& form": {
    margin: "2rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "& input::placeholder": {
    fontSize: "12px",
  },
}));

const ButtonWrapper = styled(Box)({
  width: "100%",
  "& button": {
    width: "100%",
    marginTop: "18px",
    padding: "12px 0",
    textTransform: "none",
    borderRadius: "8px", // Ensure border radius is consistent
  },
});

const LinksWrapper = styled(Box)({
  marginTop: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem", // Adjust space between the links
  "& a": {
    color: "#920000",
    textDecoration: "none",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& .separator": {
    color: "#000",
  },
});

const Business = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/register/business-info"); // Navigate to the password page
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        overflow: "hidden",
        bgcolor: "#ffffff",
      }}
    >
      <Wrapper>
        <Link to="/">
          <Box
            component="img"
            src="/images/firestation-wordmark-red.png" // Path to your logo image
            alt="Fire Station Logo"
            sx={{
              width: {
                xs: "120px", // Mobile size
                sm: "150px", // Tablet size
                md: "200px", // Desktop size
              },
              height: "auto",
              marginBottom: "1rem",
            }}
          />
        </Link>
        <Typography variant="h5" component="h1" gutterBottom fontWeight={700}>
          Enter your entity name
        </Typography>

        <form>
          <TextField
            label="Entity Name"
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder=""
            sx={{ mb: 2 }}
          />

          <ButtonWrapper>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: "#920000",
                "&:hover": { backgroundColor: "#c81010" },
                padding: "12px 0",
                textTransform: "none",
                borderRadius: "10px",
              }}
              onClick={handleNext}
            >
              Next
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>

      <LinksWrapper mt={2}>
        <Typography variant="body2">
          <a href="/register">Sign up as an individual</a>
        </Typography>
        <Typography className="separator" variant="body2">
          or
        </Typography>
        <Typography variant="body2">
          <a href="/login">Log in</a>
        </Typography>
      </LinksWrapper>
    </Box>
  );
};

export default Business;
