import React from "react";
import { Box, Typography, styled, Link } from "@mui/material";

// Define styled components using MUI's styled API
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

const ReadMoreLink = styled(Link)({
    display: "inline-block",
    marginTop: "1rem",
    fontSize: "1rem",
    color: "#ffffff",
    backgroundColor: "#920000",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    textDecoration: "none",
    "&:hover": {
        backgroundColor: "#7d0000",
    },
});

const Mission = () => {
    return (
        <StyledBox>
            <StyledTypography variant="h3">
                Our Mission
            </StyledTypography>
            <StyledParagraph>
                Fire Station is the world’s newest blockchain ecosystem,
                with a product suite that includes a digital asset exchange.
                Our mission is to be the infrastructure provider for crypto in the future.
            </StyledParagraph>
            <ReadMoreLink href="">
                Read More
            </ReadMoreLink>
        </StyledBox>
    );
};

export default Mission;
