import React from "react";
import { Box, Link, styled } from "@mui/material";
import { LinkProps } from "@mui/material/Link";

// Define a type for the props
interface StyledLinkProps extends LinkProps {
  isActive?: boolean;
}

// Styled components
const NavWrapper = styled(Box)(({ theme }) => ({
  padding: "1rem 0",
  [theme.breakpoints.down("sm")]: {
    // display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },
}));

const NavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledLinkProps>(({ isActive, theme }) => ({
  marginRight: "3rem",
  fontSize: "1.1rem",
  color: "#000",
  textDecoration: "none",
  borderBottom: isActive ? "2px solid #920000" : "none",
  fontWeight: isActive ? "bold" : "normal",
  "&:hover": {
    textDecoration: "underline",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem", // Smaller font size on mobile
    margin: "0rem 0", // Space between items on mobile
    marginLeft: "1rem"
  },
}));

// PageNav Component
const PageNav: React.FC<{ activePage: string }> = ({ activePage }) => {
  return (
    <NavWrapper>
      <NavLink href="/about-us" isActive={activePage === "about"}>
        About
      </NavLink>
      <NavLink href="/careers" isActive={activePage === "careers"}>
        Careers
      </NavLink>
      <NavLink href="/press" isActive={activePage === "press"}>
        Press
      </NavLink>
      <NavLink href="/community" isActive={activePage === "community"}>
        Community
      </NavLink>
    </NavWrapper>
  );
};

export default PageNav;
