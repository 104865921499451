import React from "react";

export const cardData = [
    {
        label: "Fire Station Exchange",
        description: "Fire Station Exchange is the largest crypto exchange by trade volume.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="40" height="40">
                <path d="M21.5 8.5a6 6 0 11-12 0 6 6 0 0112 0z" fill="#000000"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M8.5 9.5a6 6 0 110 12 6 6 0 010-12zm0 8.5L6 15.5 8.5 13l2.5 2.5L8.5 18z" fill="url(#spot-g_svg__paint0_linear)"></path>
                <path d="M9 3H3v6l6-6z" fill="url(#spot-g_svg__paint1_linear)"></path>
                <path d="M15 21h6v-6l-6 6z" fill="url(#spot-g_svg__paint2_linear)"></path>
                <defs>
                    <linearGradient id="spot-g_svg__paint0_linear" x1="8.5" y1="21.5" x2="8.5" y2="9.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                    <linearGradient id="spot-g_svg__paint1_linear" x1="6" y1="9" x2="6" y2="3" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                    <linearGradient id="spot-g_svg__paint2_linear" x1="18" y1="21" x2="18" y2="15" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
    {
        label: "Fire Station Research",
        description: "Fire Station Research provides institutional-grade analysis, in-depth insights, and unbiased information to all participants in the digital asset industry.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="40" height="40">
                <path d="M7.5 2.5l-2 2L4 3l2-2 1.5 1.5z" fill="#000000"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M12 20a5 5 0 01-3.536-8.536l1.243-1.242-1.414-1.414L7.05 10.05A7 7 0 0012 22v-2z" fill="#000000"></path>
                <path d="M13 20h9v-2h-9v2zM4 20h18v2H4v-2z" fill="#000000"></path>
                <path d="M16 8l-5 5-7-7 5-5 7 7z" fill="url(#research-g_svg__paint0_linear)"></path>
                <path d="M10.585 12.585l5-5L17 9l-5 5-1.414-1.415z" fill="#000000"></path>
                <path d="M19.035 12.5a2.5 2.5 0 10-3.535 3.536 2.5 2.5 0 003.535-3.536z" fill="url(#research-g_svg__paint1_linear)"></path>
                <defs>
                    <linearGradient id="research-g_svg__paint0_linear" x1="13.5" y1="10.5" x2="6.5" y2="3.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                    <linearGradient id="research-g_svg__paint1_linear" x1="19.035" y1="16.035" x2="15.5" y2="12.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
    {
        label: "Fire Station Academy",
        description: "Fire Station Academy is an open-access learning hub, providing free blockchain and crypto education in over 10 languages.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="40" height="40">
                <path d="M7 14.002L3.248 10.25l9.75 4.25 9.75-4.25L19 13.998V17.5l-6 6-6-6v-3.498z" fill="#000000"></path>
                <path d="M13.293 12.207l-6-6L3.25 10.25 13 20l9.75-9.75L13 .5 8.707 4.793l6 6-1.414 1.414z" fill="url(#academy-g_svg__paint0_linear)"></path>
                <path d="M2.75 14L.5 16.25l2.25 2.25L5 16.25 2.75 14z" fill="#920000"></path>
                <defs>
                    <linearGradient id="academy-g_svg__paint0_linear" x1="13" y1="20" x2="13" y2="0.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
    {
        label: "Fire Station Charity",
        description: "Fire Station Charity is a non-profit organization dedicated to building a future where Web3 technology is used as a force of good.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="40" height="40">
                <path transform="matrix(1 0 0 -1 3 21)" fill="url(#charity-g_svg__paint0_linear)" d="M0 0h18v18H0z"></path>
                <path d="M7 14.002L3.248 10.25l9.75 4.25 9.75-4.25L19 13.998V17.5l-6 6-6-6v-3.498z" fill="#000000"></path>
                <defs>
                    <linearGradient id="charity-g_svg__paint0_linear" x1="11.999" y1="14" x2="11.999" y2="1.497" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
    {
        label: "FireSea NFT",
        description: "FireSea NFT is the official NFT marketplace of Fire Station, dedicated to building a community-focused platform that enhances user experience.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="40" height="40">
                <path transform="matrix(1 0 0 -1 3 21)" fill="url(#nft-marketplace-g_svg__paint0_linear_10143_17)" d="M0 0h18v18H0z"></path>
                <path d="M4 12l8-8 8 8-8 8-8-8z" fill="#000000"></path>
                <path d="M8 8v8h8V8H8z" fill="url(#nft-marketplace-g_svg__paint1_linear_10143_17)"></path>
                <path d="M9.5 12l2.5 2.5 2.5-2.5L12 9.5 9.5 12zM5 16l3 3H5v-3zM5 8l3-3H5v3zM16 5h3v3l-3-3zM16 19h3v-3l-3 3z" fill="#000000"></path>
                <defs>
                    <linearGradient id="nft-marketplace-g_svg__paint0_linear_10143_17" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                    <linearGradient id="nft-marketplace-g_svg__paint1_linear_10143_17" x1="12" y1="16" x2="12" y2="8" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
    {
        label: "Fire Station Square",
        description: "Fire Station Square is a single touchpoint for the latest trends in Web3, displaying a range of content from crypto experts, enthusiasts, and media sources as it goes live.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" width="40" height="40">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 4h40.001v32h-20.92l-9 9v-9H4.001V4Zm13.081 9.73c8.286 0 15.002 6.716 15.002 15.001h4c0-10.494-8.507-19.002-19.002-19.002v4Zm7 15.001a7 7 0 0 0-7-7v-4c6.075 0 11 4.925 11 11h-4Zm-7 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="url(#a)"></path>
                <path d="M17.081 13.73c8.286 0 15.002 6.716 15.002 15.001h4c0-10.494-8.507-19.002-19.002-19.002v4Z" fill="#000000"></path>
                <path d="M24.081 28.731a7 7 0 0 0-7-7v-4c6.075 0 11 4.925 11 11h-4ZM20.081 28.732a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000000"></path>
                <defs>
                    <linearGradient id="a" x1="24.001" y1="4" x2="24.001" y2="45" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#920000"></stop>
                        <stop offset="1" stopColor="#920000"></stop>
                    </linearGradient>
                </defs>
            </svg>
        ),
    },
];
