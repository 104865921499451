import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface CategorySelectorProps {
  categories: string[];
  onSelectCategory: (category: string) => void;
  selectedCategory: string;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  categories,
  onSelectCategory,
  selectedCategory,
}) => {
  return (
    <Box sx={{ marginBottom: 4, overflowX: "auto" }}>
      <Box
        sx={{
          display: "flex",
          gap: {
            xs: 0, // Small gap for mobile devices
            sm: 2, // Larger gap for tablets and up
          },
          justifyContent: "space-between", // Distribute space evenly

        }}
      >
        {categories.map((category) => (
          <Button
            key={category}
            onClick={() => onSelectCategory(category)}
            variant={selectedCategory === category ? "contained" : "text"}
            sx={{
              textTransform: "none",
              backgroundColor:
                selectedCategory === category
                  ? "rgba(0, 0, 0, 0.04)"
                  : "transparent",
              borderRadius: "10px",
              padding: {
                xs: "4px", // Uniform padding
                sm: "10px", // Padding for larger screens
              },
              minWidth: "100px", // Minimum width to fit text
              flex: 1, // Allow buttons to grow and fill available space
              "&:hover": {
                backgroundColor:
                  selectedCategory === category
                    ? "rgba(0, 0, 0, 0.12)"
                    : "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <Typography
              variant="body1"
              color={selectedCategory === category ? "textPrimary" : "textSecondary"}
              sx={{
                fontSize: {
                  xs: "0.875rem", // Mobile font size
                  md: "1rem", // Desktop font size
                },
                textAlign: "center", // Center text within the button
              }}
            >
              {category}
            </Typography>
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default CategorySelector;
