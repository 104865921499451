import React from "react";
import { Box, Grid, Container, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

// Sample Data
const Data = [
  {
    img: "./images/firemask.svg",
    title: "FireMask",
    decs: "FireMask will be available as a browser extension and as a mobile app to manage your digital assets.",
    btn: "Learn More",
    href: "https://firemask.io", // Add your link here
  },
  {
    img: "./images/firesea.png",
    title: "FireSea",
    decs: "Multichain digital marketplace for collectibles and NFTs. Buy, sell, and discover exclusive digital items.",
    btn: "Learn More",
    href: "https://firesea.io", // Add your link here
  },
];

// Styled components
const Section = styled(Box)({
  padding: "2rem 0",
});

const Heading = styled(Typography)({
  fontSize: "1.6rem",
  fontWeight: "bold",
  marginBottom: "2rem",
  "@media (max-width:600px)": {
    fontSize: "16px !important",
  },
});

const Card = styled(Box)({
  textAlign: "center",
  padding: "1rem",
  maxWidth: "500px",
  margin: "0 auto",
});

const StyledButton = styled(Button)({
  backgroundColor: "#920000",
  color: "#fff",
  margin: "2rem 0",
  padding: "0.6rem 2rem",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#920000",
    opacity: "0.7",
  },
});

const Description = styled(Typography)({
  color: "#6c757d", // Replace with theme.palette.text.secondary if using theme
  marginBottom: "1rem",
  wordBreak: "break-word",
  "@media (max-width:600px)": {
    fontSize: "0.875rem",
  },
});

const FutureFinance: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Heading variant="h4">Fire Station is the Future</Heading>
          </Grid>
          {Data.map((item, index) => {
            const { img, title, decs, btn, href } = item;
            return (
              <Grid item md={6} key={index}>
                <Card>
                  <img src={img} alt={title} width="76px" />
                  <Typography variant="h6">{title}</Typography>
                  <Description variant="body1">{decs}</Description>
                  <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <StyledButton variant="contained">
                      {btn}
                    </StyledButton>
                  </a>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

export default FutureFinance;
