import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

// Plus Icon Component
const PlusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    {/* Vertical line */}
    <path
      d="M12 5v14M5 12h14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SvgIcon>
);

// Minus Icon Component
const MinusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    {/* Horizontal line */}
    <path
      d="M5 12h14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export { PlusIcon, MinusIcon };
