import React from "react";
import { Box, Container, Grid, TextField } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { styled } from "@mui/material/styles";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "0 auto",
    padding: "3rem 0 2rem",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

const MainContent = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
});

const SearchInput = styled(TextField)({
    width: "100%",
    "& .MuiInputBase-root": {
        height: "40px",
    },
});

const TabList = styled(Box)({
    display: "flex",
    gap: "16px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    padding: "0 16px",
    height: "38px",
    alignItems: "center",
    "& .tab": {
        fontSize: "12px",
        fontWeight: "500",
        cursor: "pointer",
        padding: "0 8px",
        borderRadius: "4px",
        transition: "background-color 0.3s",
        "&.active": {
            backgroundColor: "#920000",
            color: "#fff",
        },
    },
});

const ListItem = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderBottom: "1px solid #ddd",
    "& .symbol": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontSize: "12px",
    },
    "& .price-change": {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
    },
    "& .price": {
        color: "#000",
    },
    "& .change": {
        color: "#f00", // Red for negative changes
    },
});

const Trade = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            <Navbar />
            <MainContent>
                <Wrapper>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12}>
                                <Box sx={{ mb: 2 }}>
                                    <SearchInput placeholder="Search" variant="outlined" />
                                </Box>
                                <TabList>
                                    <Box className="tab active">USDT</Box>
                                    <Box className="tab">FDUSD</Box>
                                    <Box className="tab">USDC</Box>
                                    <Box className="tab">TUSD</Box>
                                    <Box className="tab">BNB</Box>
                                    <Box className="tab">BTC</Box>
                                    <Box className="tab">ALTS</Box>
                                    <Box className="tab">FIAT</Box>
                                    <Box className="tab">Zones</Box>
                                    <Box className="tab">Margin</Box>
                                    <Box className="tab">Grid</Box>
                                </TabList>
                                <Box
                                    sx={{
                                        height: "285px",
                                        overflowY: "auto",
                                        border: "1px solid #ddd",
                                    }}
                                >
                                    <ListItem>
                                        <Box className="symbol">
                                            <div>1000SATS/USDT</div>
                                            <div>5x</div>
                                        </Box>
                                        <Box className="price-change">
                                            <div className="price">0.0002966</div>
                                            <div className="change">-6.23%</div>
                                        </Box>
                                    </ListItem>
                                    {/* Add more ListItems as needed */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Wrapper>
            </MainContent>
            <Footer />
        </Box>
    );
};

export default Trade;
