import React from "react";
import { Box, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

// Define styled components using MUI's styled API
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

// StyledButton with custom colors
const StyledButton = styled(Button)({
  backgroundColor: "#920000", // Default color
  color: "#fff", // Text color
  textTransform: "none",
  borderRadius: "10px",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "#c81010", // Hover color
  },
  "&:active": {
    backgroundColor: "#8a0000", // Click color
  },
  "&:focus": {
    outline: "none",
  },
});

const HeaderButtons = () => {
  return (
    <StyledBox>
      <Link to="/partner-with-us" style={{ textDecoration: "none" }}>
        <StyledButton variant="contained">Partner with us</StyledButton>
      </Link>
    </StyledBox>
  );
};

export default HeaderButtons;
