import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  styled,
} from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import PageNav from "../../Components/Navbar/PageNav";
import AboutTitle from "../../Components/Community/ComTitle";
import MobileImage from "../../Components/Header/MobileImage";
import InfoBlock from "../../Components/Community/InfoBlock";
import AboutWelcomeImage from "../../Components/Community/ComWelcomeImage";
import { useTheme } from "@mui/material/styles";
import Mission from "../../Components/Community/Mission";
import Ecosystem from "../../Components/Community/Ecosystem";
import Founders from "../../Components/Community/Founders";
import Applicants from "../../Components/Community/Applicants";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)({
  padding: "1rem 0 2rem",
  "& h6": {
    fontSize: "2rem",
    fontWeight: 500,
    marginTop: "2rem",
  },
  "& h4": {
    fontSize: "3rem",
    fontWeight: "bold",
  },
  "& img": {
    width: "75%",
    height: "75%",
  },
});

const Community = () => {
  const theme = useTheme();

  return (
    <>
      <Navbar />
      <CssBaseline />
      <Wrapper>
        <Container>
          <Grid container spacing={2}>

            <PageNav activePage="community" />

            <Box
              sx={{
                padding: "4rem 0",
                [theme.breakpoints.down("sm")]: { padding: "3rem 0 1rem" },
              }}
            >
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5} display={{ xs: "block", sm: "none" }}>
                    <MobileImage />
                  </Grid>
                  <Grid item md={7} sm={12}>
                    <AboutTitle />
     
                  </Grid>
                  <Grid item md={5}>
                    <AboutWelcomeImage />
                  </Grid>
                  <InfoBlock />
                </Grid>
              </Container>
            </Box>


            <Mission />
            <Ecosystem />
            <Founders />
            <Applicants />

          </Grid>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Community;
