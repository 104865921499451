import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { styled } from "@mui/material/styles";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  width: "40%",
  margin: "0 auto",
  padding: "3rem 0 2rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  "& h5": {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "& p": {
    color: "rgba(71, 77, 87, 0.8)",
    fontSize: "0.9rem",
  },
}));

const MainContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

// Styled link component
const StyledLink = styled('a')({
  color: 'inherit', // Inherit color from parent
  textDecoration: 'underline', // Remove underline
});

const PartnerWithUs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <MainContent>
        <Wrapper>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Box>
                  <Typography variant="h5">Partner with us</Typography>
                  <Typography variant="body1">
                    For partnership opportunities, please contact us at{" "}
                    <StyledLink href="mailto:partners@firestationcex.com">
                      partners@firestationcex.com
                    </StyledLink>. We look forward to exploring potential collaborations with you.
                  </Typography>
                  {/* 
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                      <Grid item md={6} sm={12}>
                        <Box sx={{ xs: "0", sm: "0 10px 0 0px" }}>
                          <p htmlFor="">First Name</p>
                          <TextField
                            fullWidth
                            id="firstname"
                            name="firstname"
                            placeholder="First Name"
                            variant="outlined"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.firstname &&
                              Boolean(formik.errors.firstname)
                            }
                            helperText={
                              formik.touched.firstname && formik.errors.firstname
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item md={6} sm={12}>
                        <Box
                          sx={{
                            margin: {
                              xs: "0",
                              sm: "0 0 0 10px",
                            },
                          }}
                        >
                          <p htmlFor="">Last Name</p>
                          <TextField
                            fullWidth
                            id="lastname"
                            name="lastname"
                            placeholder="Last Name"
                            variant="outlined"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.lastname &&
                              Boolean(formik.errors.lastname)
                            }
                            helperText={
                              formik.touched.lastname && formik.errors.lastname
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12} lg={12}>
                        <Box>
                          <p htmlFor="">Company Email</p>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            placeholder="Enter your email address"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email && Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12} lg={12}>
                        <Box>
                          <p htmlFor="">Company Name</p>
                          <TextField
                            fullWidth
                            id="companyname"
                            name="companyname"
                            variant="outlined"
                            placeholder="Business Name"
                            value={formik.values.companyname}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.companyname &&
                              Boolean(formik.errors.companyname)
                            }
                            helperText={
                              formik.touched.companyname &&
                              formik.errors.companyname
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12} lg={12}>
                        <Box>
                          <p htmlFor="">Company Website</p>
                          <TextField
                            fullWidth
                            id="companyURL"
                            name="companyURL"
                            variant="outlined"
                            placeholder="https://"
                            value={formik.values.companyURL}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.companyURL &&
                              Boolean(formik.errors.companyURL)
                            }
                            helperText={
                              formik.touched.companyURL &&
                              formik.errors.companyURL
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Box>
                          <p htmlFor="">Company Type</p>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={age}
                              onChange={handleChange}
                            >
                              <MenuItem value={"DeFi"}>DeFi</MenuItem>
                              <MenuItem value={"Press"}>Press</MenuItem>
                              <MenuItem value={"Venture Capital"}>
                                Venture Capital
                              </MenuItem>
                              <MenuItem value={"Angel Investor"}>
                                Angel Investor
                              </MenuItem>
                              <MenuItem value={"Others"}>Others</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Disclaimer>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            required
                            style={{
                              height: "20px !important",
                              marginRight: "10px",
                              "@media (max-width: 600px)": {
                                height: "20px !important",
                              },
                              "@media (max-width: 300px)": {
                                height: "20px !important",
                              },
                            }}
                          />
                          <p>
                            I agree to Fire Station’s Terms of Use and Privacy
                            Policy. By clicking 'Submit', you are submitting a
                            request to explore a business opportunity with Fire
                            Station.
                          </p>
                        </Disclaimer>
                      </Grid>
                    </Grid>

                    <SubmitButton
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </SubmitButton>
                  </form>
                  */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      </MainContent>
      <Footer />
    </Box>
  );
};

export default PartnerWithUs;
