import React from "react";
import { Box, Typography, Grid, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
    },
}));

// Core Values content
const valueData = [
    {
        title: "User-Focused",
        description: "We protect our users by putting our users' needs first and delivering quality service.",
        imgSrc: "/images/3d-firechain.png",
    },
    {
        title: "Collaboration",
        description: "We communicate openly. We work as a team towards shared goals to build the ecosystem together.",
        imgSrc: "/images/3d-firechain.png",
    },
    {
        title: "Hardcore",
        description: "We are results driven. We get things done. We are passionate and work hard. When we fail, we learn fast, and pick ourselves up.",
        imgSrc: "/images/3d-firechain.png",
    },
    {
        title: "Freedom",
        description: "We execute responsibly and autonomously. We empower those around us. Our team is diverse and we challenge the status quo.",
        imgSrc: "/images/3d-firechain.png",
    },
    {
        title: "Humility",
        description: "We accept critical feedback. We treat everyone as equals. We are modest about our success.",
        imgSrc: "/images/3d-firechain.png",
    },
];

// Values Grid Layout
const ValuesGrid = () => (
    <Grid container spacing={3}>
        {/* Left side with one large (User-Focused) and one smaller (Collaboration) block */}
        <Grid item xs={12} md={5} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {/* User-Focused (Large Block) */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 2,
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: { xs: "1rem", md: "1rem" },
                    position: "relative",
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    {valueData[0].title}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1rem", color: "text.secondary" }}>
                    {valueData[0].description}
                </Typography>
                <Box
                    component="img"
                    src={valueData[0].imgSrc}
                    alt={valueData[0].title}
                    sx={{ width: "40%", height: "auto", marginTop: "1rem" }}
                />
            </Box>

            {/* Collaboration (Smaller Block) */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: { xs: "1rem", md: "1rem" },
                    position: "relative",
                }}
            >
                <Box>
                    <Typography variant="h6" fontWeight="bold">
                        {valueData[1].title}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: "1rem", color: "text.secondary", maxWidth: "30rem" }}>
                        {valueData[1].description}
                    </Typography>
                </Box>
                <Box
                    component="img"
                    src={valueData[1].imgSrc}
                    alt={valueData[1].title}
                    sx={{
                        width: "40%",
                        height: "auto",
                        // objectFit: "cover",
                        right: { md: "2rem" },
                        bottom: { md: "1rem" },
                    }}
                />
            </Box>
        </Grid>

        {/* Right side with 3 smaller blocks */}
        <Grid item xs={12} md={6}>
            <Grid container direction="column" gap="1rem">
                {valueData.slice(2).map((value, index) => (
                    <Grid
                        item
                        xs={12}
                        key={index}
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column-reverse", md: "row" },
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid #ddd",
                            borderRadius: "16px",
                            padding: { xs: "1rem", md: "1rem" },
                            position: "relative",
                        }}
                    >
                        <Box>
                            <Typography variant="h6" fontWeight="bold">
                                {value.title}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: "1rem", color: "text.secondary", maxWidth: "30rem" }}>
                                {value.description}
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            src={value.imgSrc}
                            alt={value.title}
                            sx={{
                                width: "30px",
                                height: "auto",
                                objectFit: "cover",
                                right: { md: "2rem" },
                                bottom: { md: "1rem" },
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
);

// Combined Values Section
const Values = () => {
    return (
        <Box>
            {/* Introduction Section */}
            <StyledBox>
                <StyledTypography variant="h3">
                    Our Values
                </StyledTypography>
                <StyledParagraph>
                    Fire Station Core Values guide our behavior, decisions, and action,
                    enabling unified collaboration across our diverse, international teams.
                </StyledParagraph>
            </StyledBox>

            {/* Grid Layout of Core Values */}
            <ValuesGrid />
        </Box>
    );
};

export default Values;
