import React from "react";
import { Box, Typography, styled, Link } from "@mui/material";

// Define styled components using MUI's styled API
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left", // Ensure text is aligned to the left
    margin: "2rem 0", // Add vertical spacing
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem", // Adjust padding for smaller screens
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem", // Add spacing below the title
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem", // Adjust font size for smaller screens
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%", // Set width to 100% of the container
    maxWidth: "500px", // Set a maximum width for readability
    margin: "0", // Remove automatic margins to align with title
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem", // Adjust font size for smaller screens
    },
}));

const ReadMoreLink = styled(Link)({
    display: "inline-block",
    marginTop: "1rem",
    fontSize: "1rem",
    color: "#ffffff",
    backgroundColor: "#920000",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    textDecoration: "none",
    "&:hover": {
        backgroundColor: "#7d0000",
    },
});

const AboutTitle = () => {
    return (
        <StyledBox>
            <StyledTypography variant="h3">
                Careers at Fire Station
            </StyledTypography>
            <StyledParagraph>
                Join our quest to increase the Freedom of Money
            </StyledParagraph>
            <ReadMoreLink href="">
                View Openings
            </ReadMoreLink>
        </StyledBox>
    );
};

export default AboutTitle;
