import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

// Define styled components using MUI's styled API
const StyledStack = styled(Stack)(({ theme }) => ({
  margin: "3rem 0 0",
  [theme.breakpoints.down("sm")]: {
    margin: "1rem 0 0",
    width: "100%",
    justifyContent: "center", // Center items horizontally
    flexWrap: "wrap", // Allow wrapping of items if necessary
    gap: "10px", // Small space between items
  },
  [theme.breakpoints.up("sm")]: {
    width: "100%",
    flexDirection: "row", // Ensure horizontal alignment on larger screens
    justifyContent: "space-between", // Distribute items evenly
    alignItems: "center", // Align items vertically centered
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  textAlign: "left",
  flex: "1 1 auto", // Allow flexible growth and shrink
  maxWidth: "300px", // Adjust max width for each box
  display: "flex",
  alignItems: "center",
  gap: "1rem", // Space between icon and text
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%", // Full width on smaller screens
    flexDirection: "column",
    alignItems: "center", // Center items horizontally on mobile
    textAlign: "center", // Center text horizontally on mobile
    gap: "0.5rem", // Smaller gap between icon and text on mobile
  },
  "& h6": {
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "0.2rem",
    },
  },
  "& span": {
    color: "#707a8a",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  svg: {
    width: "100%",
    height: "100%",
  },
});

const InfoBlock = () => {
  return (
    <StyledStack>
      <InfoBox>
        <IconWrapper>
          <svg
            className="bn-svg"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 3.5a5.502 5.502 0 00-5.302 4.032 7.502 7.502 0 016.77 6.77A5.502 5.502 0 0015 3.5zM14.5 15a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zm-8 0L9 17.5l2.5-2.5L9 12.5 6.5 15zM9 4H4v5l5-5zm11 16h-5l5-5v5z"
              fill="currentColor"
            ></path>
          </svg>
        </IconWrapper>
        <Box>
          <Typography variant="h6">$0 bn</Typography>
          <Typography variant="body2">Average daily volume</Typography>
        </Box>
      </InfoBox>
      <InfoBox>
        <IconWrapper>
          <svg
            className="bn-svg"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-4l4 4-4 4-4-4 4-4z"
              fill="currentColor"
            ></path>
          </svg>
        </IconWrapper>
        <Box>
          <Typography variant="h6">0 bn</Typography>
          <Typography variant="body2">Spot transactions in 2025</Typography>
        </Box>
      </InfoBox>
      <InfoBox>
        <IconWrapper>
          <svg
            className="bn-svg"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.002 17v-5a9.113 9.113 0 00-.055-1 9.001 9.001 0 00-17.945 1v5h5v-6H5.578a6.502 6.502 0 0112.848 0h-2.424v6h.899a6.988 6.988 0 01-3.289 1.814 2 2 0 10.217 2A9.007 9.007 0 0019.486 17h1.516z"
              fill="currentColor"
            ></path>
          </svg>
        </IconWrapper>
        <Box>
          <Typography variant="h6">24/7</Typography>
          <Typography variant="body2">Customer Support</Typography>
        </Box>
      </InfoBox>
    </StyledStack>
  );
};

export default InfoBlock;
