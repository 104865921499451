import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  other?: object;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TermsOfService = () => {
  const [value] = useState(0);

  return (
    <>
      <Navbar />
      <Box
        sx={{
          padding: "3rem 0 2rem",
          display: "flex",
          flexDirection: "column",
          minHeight: "50vh",
        }}
      >
        <Container
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      marginTop: "1rem",
                      "& h5": {
                        margin: "13px 0",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      },
                      "& h6": {
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        margin: "3rem 0 0",
                      },
                    }}
                  >
                    <Typography variant="h5">TERMS OF USE</Typography>
                    <Typography variant="h6">
                      Last updated: 10 August, 2024
                    </Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Typography>Item Two</Typography>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default TermsOfService;
