import React from "react";
import { Box, Typography, styled, Button, Stack } from "@mui/material";

// Define styled components using MUI's styled API
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left", // Ensure text is aligned to the left
    margin: "2rem 0", // Add vertical spacing
    [theme.breakpoints.down("sm")]: {
        padding: "0 1rem", // Adjust padding for smaller screens
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: "1rem", // Add spacing below the title
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem", // Adjust font size for smaller screens
    },
}));

const StyledParagraph = styled("p")(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    width: "100%", // Set width to 100% of the container
    margin: "0", // Remove automatic margins to align with title
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem", // Adjust font size for smaller screens
    },
}));

const StyledButton = styled(Button)({
    backgroundColor: "#920000", // Custom background color
    color: "#fff", // White text color
    textTransform: "none", // Keep text as is
    padding: "0.5rem 1.5rem", // Add some padding
    marginTop: "1rem",
    "&:hover": {
        backgroundColor: "#760000", // Darker shade on hover
    },
});

const ImageContainer = styled(Box)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        display: "block",
        width: "50%", // Adjust as needed
        marginRight: "2rem", // Space between image and text
    },
}));

const Applicants = () => {
    return (
        <StyledBox>
            <Stack direction="row" alignItems="flex-start" spacing={2}>
                <ImageContainer>
                    <img
                        src="/images/applicants.png"
                        alt="Working at Fire Station"
                        style={{ width: "40%" }}
                    />
                </ImageContainer>
                <Box flex="1">
                    <StyledTypography variant="h3">
                        Working at Fire Station
                    </StyledTypography>
                    <StyledParagraph>
                        At Fire Station, we empower individuals to make their own choices,
                        collaborate openly, and serve our users with dedication and integrity.
                        Join the Fire Station team and work alongside some of the most talented,
                        driven, and passionate professionals in the industry.
                    </StyledParagraph>
                    <StyledButton href="/careers">
                        Explore Jobs
                    </StyledButton>
                </Box>
            </Stack>
        </StyledBox>
    );
};

export default Applicants;
