import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";

// Define styled components using MUI's styled API
const StatisticsGrid = styled(Grid)(({ theme }) => ({
  marginTop: '3rem',
  // minHeight: '204px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  // padding: '1.5rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr', // One column for mobile
    gap: '1.5rem',
  },
}));

const StatisticBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  borderRadius: '8px',
  // border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const InfoBlock = () => {
  return (
    <StatisticsGrid container>
      <StatisticBox>
        <Typography variant="h6" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>110+</Typography>
        <Typography variant="body2" sx={{ fontSize: '1rem', color: '#707a8a' }}>Nationalities</Typography>
      </StatisticBox>
      <StatisticBox>
        <Typography variant="h6" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>5000+</Typography>
        <Typography variant="body2" sx={{ fontSize: '1rem', color: '#707a8a' }}>Employees</Typography>
      </StatisticBox>
      <StatisticBox>
        <Typography variant="h6" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>100+</Typography>
        <Typography variant="body2" sx={{ fontSize: '1rem', color: '#707a8a' }}>Locations</Typography>
      </StatisticBox>
    </StatisticsGrid>
  );
};

export default InfoBlock;
