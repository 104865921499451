import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  styled,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { useNavigate, Link } from "react-router-dom";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: "400px",
  width: "100%",
  padding: "2rem",
  border: "1px solid #e0e0e0",
  borderRadius: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "80%",
    padding: "1rem",
    margin: "0 1rem",
  },
  "& h5": {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "& p": {
    color: "rgba(71, 77, 87, 0.8)",
    fontSize: "0.9rem",
  },
  "& form": {
    margin: "2rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "& input::placeholder": {
    fontSize: "12px",
  },
}));

const ButtonWrapper = styled(Box)({
  width: "100%",
  "& button": {
    width: "100%",
    marginTop: "18px",
    padding: "12px 0",
    textTransform: "none",
    borderRadius: "8px", // Ensure border radius is consistent
  },
});

const LinksWrapper = styled(Box)({
  marginTop: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem", // Space between the links
  "& a": {
    color: "#920000",
    textDecoration: "none",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& .separator": {
    margin: "0 0.1rem",
    color: "#000",
  },
});

const Register = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/login/password"); // Navigate to the password page
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        overflow: "hidden",
        bgcolor: "#ffffff",
      }}
    >
      <Wrapper>
        <Link to="/">
          <Box
            component="img"
            src="/images/firestation-wordmark-red.png" // Path to your logo image
            alt="Fire Station Logo"
            sx={{
              width: {
                xs: "120px", // Mobile size
                sm: "150px", // Tablet size
                md: "200px", // Desktop size
              },
              height: "auto",
              marginBottom: "1rem",
            }}
          />
        </Link>
        <Typography variant="h5" component="h1" gutterBottom fontWeight={700}>
          Log in
        </Typography>

        <form>
          <TextField
            label="Email/Phone number"
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="Email/Phone (without country code)"
            sx={{ mb: 2 }}
          />

          <ButtonWrapper>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: "#920000",
                "&:hover": { backgroundColor: "#c81010" },
                padding: "12px 0",
                textTransform: "none",
                borderRadius: "10px", // Ensure border radius is consistent
              }}
              onClick={handleNext}
            >
              Next
            </Button>
          </ButtonWrapper>
        </form>

        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              position: "relative",
              mb: 2,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "#e0e0e0",
              }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mx: 2,
                whiteSpace: "nowrap",
                zIndex: 1,
                backgroundColor: "white",
                padding: "0 0.5rem",
              }}
            >
              or
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "#e0e0e0",
              }}
            />
          </Box>

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              mb: 1,
              borderColor: "#0066ff",
              color: "#0066ff",
              "&:hover": { borderColor: "#0044cc", color: "#0044cc" },
              padding: "12px 0",
              textTransform: "none",
              borderRadius: "10px", // Ensure border radius is consistent
            }}
            startIcon={<GoogleIcon />}
          >
            Continue with Google
          </Button>

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              borderColor: "#000000",
              color: "#000000",
              "&:hover": { borderColor: "#333333", color: "#333333" },
              padding: "12px 0",
              textTransform: "none",
              borderRadius: "10px", // Ensure border radius is consistent
            }}
            startIcon={<AppleIcon />}
          >
            Continue with Apple
          </Button>
        </Box>


      </Wrapper>

      <LinksWrapper mt={2}>
        <Typography variant="body2">
          <a href="/register">Create a Fire Station Account</a>
        </Typography>
      </LinksWrapper>

    </Box>
  );
};

export default Register;
