// FooterMenuItems.ts
export interface MenuItem {
  id: number;
  title: string;
  href?: string; // Make href optional because some items might not have it
}

export const FireStationItems: MenuItem[] = [
  // {
  //   id: 1,
  //   title: "About",
  //   href: "/about-us",
  // },
];

export const ProductsItems: MenuItem[] = [];

export const AboutItems: MenuItem[] = [
  // {
  //   id: 1,
  //   title: "About",
  //   href: "/about-us",
  // },
  // {
  //   id: 1,
  //   title: "Career",
  //   href: "/careers",
  // },
  {
    id: 2, // Make sure id values are unique
    title: "Privacy",
    href: "/privacy-policy",
  },
  {
    id: 3,
    title: "Terms",
    href: "/terms-of-service",
  },
  {
    id: 4,
    title: "Risk Warning",
    href: "/risk-warning",
  },
  {
    id: 5,
    title: "Blog",
    href: "/blog",
  },
];

export const SupportItems: MenuItem[] = [
  {
    id: 1,
    title: "Support",
  },
  {
    id: 2,
    title: "Feedback & Suggestions",
  },
  // {
  //   id: 3,
  //   title: "Fire Station Verify",
  //   href: "/official-verification",
  // },
  {
    id: 4,
    title: "Trading Rules",
  }
];

export const BuyCryptoItems: MenuItem[] = [
  {
    id: 1,
    title: "DonaSwap v2",
    href: "https://donaswap.io/swap?outputCurrency=0x229c32460c6beac113e720ac4a7495b57f53f7cf",
  },
  {
    id: 2,
    title: "PancakeSwap v2",
    href: "https://pancakeswap.finance/swap?outputCurrency=0x229c32460c6beac113e720ac4a7495b57f53f7cf",
  },
  {
    id: 3,
    title: "Coinsbit",
    href: "https://coinsbit.io/trade/DONA_USDT",
  },
];

export const EcosystemItems: MenuItem[] = [
  {
    id: 1,
    title: "FireChain",
    href: "https://thefirechain.com",
  },
  {
    id: 2,
    title: "FireMask",
    href: "https://firemask.io",
  },
  {
    id: 3,
    title: "FireSea",
    href: "https://firesea.io",
  },
  {
    id: 4,
    title: "DonaSwap",
    href: "https://donaswap.io",
  },
  {
    id: 5,
    title: "Punk Knights",
    href: "https://punkknights.io",
  },
];

export const LearnItems: MenuItem[] = [
  {
    id: 1,
    title: "Crypto Prices",
  },
  {
    id: 2,
    title: "Bitcoin Price",
  },
  {
    id: 3,
    title: "Ethereum Price",
  },
];
