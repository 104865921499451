import React from "react";
import { Box, styled } from "@mui/material";

// Define a styled component for the image container
const ImageContainer = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const WebImage = () => {
  return (
    <ImageContainer>
      <img
        src="./images/3d-firechain.png"
        style={{ width: "100%" }}
        alt="Firechain"
      />
    </ImageContainer>
  );
};

export default WebImage;
