import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CardContent,
} from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { styled } from "@mui/material/styles";

interface Coin {
  symbol: string;
  price_change_percentage_24h?: number;
  current_price: number;
  market_cap: number;
  total_volume: number;
  image: string;
}

interface Market {
  pair: string;
  price: number;
  change: string;
  logo: string;
}

const Wrapper = styled(Box)(({ theme }) => ({
  width: "95%",
  margin: "0 auto",
  padding: "3rem 0 2rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "1rem 0",
  },
}));

const MainContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const MarketTable = styled(Table)({
  minWidth: 150,
  "& th": {
    fontWeight: "light",
  },
  "& td": {
    fontSize: "0.7rem",
  },
});

const Markets = () => {
  const [marketData, setMarketData] = useState({
    hotCoins: [] as Market[],
    newListings: [] as Market[],
    topGainers: [] as Market[],
    topVolume: [] as Market[],
  });

  const [overview, setOverview] = useState({
    marketCap: 0,
    volume: 0,
  });

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await axios.get<Coin[]>(
          "https://api.coingecko.com/api/v3/coins/markets",
          {
            params: {
              vs_currency: "usd",
              ids: "bitcoin,ethereum,tether,toncoin,donaswap,solana,binancecoin", // Add more as needed
            },
          }
        );

        const data = response.data.reduce<{ [key: string]: Market }>(
          (acc, coin: Coin) => {
            acc[coin.symbol.toUpperCase()] = {
              pair: coin.symbol.toUpperCase(),
              price: coin.current_price,
              change: (coin.price_change_percentage_24h ?? 0).toFixed(2) + "%",
              logo: coin.image,
            };
            return acc;
          },
          {}
        );

        setMarketData({
          hotCoins: [data.BTC, data.ETH, data.USDT].filter(
            (coin) => coin
          ) as Market[],
          newListings: [data.USDT, data.ETH, data.DONA].filter(
            (coin) => coin
          ) as Market[],
          topGainers: [data.SOL, data.DONA, data.ETH].filter(
            (coin) => coin
          ) as Market[],
          topVolume: [data.BTC, data.BNB, data.ETH].filter(
            (coin) => coin
          ) as Market[],
        });

        // Calculate and set overview data
        const totalMarketCap = response.data.reduce(
          (sum, coin) => sum + coin.market_cap,
          0
        );
        const totalVolume = response.data.reduce(
          (sum, coin) => sum + coin.total_volume,
          0
        );

        setOverview({
          marketCap: totalMarketCap,
          volume: totalVolume,
        });
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };

    fetchMarketData();
  }, []);

  const renderMarketTable = (title: string, data: Market[]) => (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ borderRadius: "12px" }}>
        <CardContent sx={{ paddingBottom: "0px" }}>
          <Typography variant="subtitle2" gutterBottom>
            {title}
          </Typography>
        </CardContent>
        <TableContainer>
          <MarketTable>
            <TableBody>
              {data.map((market) => (
                <TableRow key={market.pair}>
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={market.logo}
                        alt={market.pair}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                        }}
                      />
                      {market.pair}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: market.change.startsWith("-") ? "red" : "green",
                    }}
                  >
                    ${market.price} {/* Display the price */}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: market.change.startsWith("-") ? "red" : "green",
                    }}
                  >
                    {market.change} {/* Ensure the percentage is displayed */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MarketTable>
        </TableContainer>
      </Card>
    </Grid>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Navbar />
      <MainContent>
        <Wrapper>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={5}>
                            {/* Removed OverviewCard */}
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography 
                        variant="subtitle1" 
                        gutterBottom
                        align="center"
                                            sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '0.8rem' }, fontWeight: 'bold' }}
                      >
                        Market Overview
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="subtitle1"
                        align="center"
                                            sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '0.8rem' }, fontWeight: 'bold' }}
                      >
                        Total Market Cap: ${overview.marketCap.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="subtitle1"
                        align="center"
                                            sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '0.8rem' }, fontWeight: 'bold' }}
                      >
                        24h Trading Volume: ${overview.volume.toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
            </Grid>
            <Container>
              <Grid container spacing={3}>
                {renderMarketTable("Hot Coins", marketData.hotCoins)}
                {renderMarketTable("New Listings", marketData.newListings)}
                {renderMarketTable("Top Gainers", marketData.topGainers)}
                {renderMarketTable("Top Volume", marketData.topVolume)}
              </Grid>
            </Container>
          </Grid>
        </Wrapper>
      </MainContent>
      <Footer />
    </Box>
  );
};

export default Markets;
